import { ChangeDetectorRef, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';
import { ToastService } from '../../shared/toast.service';
import { MenuItem } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalDataService } from '../../services/global-data.service';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import * as lottie from 'lottie-web';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.scss'
})
export class ApplicationsComponent {

  activeAction: string | null = null;
  openApplications: any;
  applicationOwners: any[] = [];
  selectedOwner: any;
  selectedApplication: any;
  expandedRows: any = {};
  showSpinner: boolean = false;
  dataLoading: boolean = false;
  activeItem: MenuItem | undefined;
  tabMenuItems: any[] = [];
  visible: boolean = false;
  EditVisible: boolean = false;
  ApproveVisible: boolean = false;
  rejectVisible: boolean = false;
  deleteVisible: boolean = false;
  basicDetailsForm: FormGroup;
  aadhaarDataForm: FormGroup;
  PANDataForm: FormGroup;
  bankDataForm: FormGroup;
  applicationDetailsForm: FormGroup;
  outletdetailsForm: FormGroup;
  rejectForm: FormGroup;
  user: any;
  userData: any;
  steps: any[] = [];
  selectedStep: any = null;
  fssai: any[] = [];
  selectedFssai: any = null;
  gst: any[] = [];
  selectedGst: any = null;
  udyam: any[] = [];
  selectedUdyam: any = null;
  isDataLoading: boolean = false;
  showAnimationContainer = false;
  @ViewChildren('animationContainer') animationContainers!: QueryList<ElementRef>;
  @ViewChildren('expansionContainer') expansionContainers!: QueryList<ElementRef>;
  animationInstance: any;
  addInstances: any[] = [];
  animationInstances: any[] = [];
  expansionAnimationInstances: any[] = [];

  // Map of animation container IDs to their respective animation file paths
  animationFilePaths: { [key: string]: string } = {
    trash: 'assets/animIcons/trashIcon.json',
    edit: 'assets/animIcons/penIcon.json',
    pause: 'assets/animIcons/pauseIcon.json',
    add: 'assets/animIcons/plusIcon.json',
    send: 'assets/animIcons/sendIcon.json',
    info: 'assets/animIcons/infoIcon.json',
    check: 'assets/animIcons/checkIcon.json',
  };

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService, private toastService: ToastService, private cdr: ChangeDetectorRef, private http: HttpClient, private globalDataService: GlobalDataService, private authService: AuthService) {
    this.tabMenuItems = [
      // { label: 'Log History', icon: 'fa-solid fa-bowl-food' },
      { label: 'Owner Details' },
      { label: 'Application Details' },
    ];
    this.activeItem = this.tabMenuItems[0]

    this.basicDetailsForm = this.fb.group({
      ownerId: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emailId: [''],
      mobileNumber: ['', [Validators.required]]
    });

    this.aadhaarDataForm = this.fb.group({
      aadhaarNumber: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      gender: ['', [Validators.required]]
    });

    this.PANDataForm = this.fb.group({
      panNumber: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      category: ['', [Validators.required]]
    });

    this.bankDataForm = this.fb.group({
      bankAccountNumber: ['', [Validators.required]],
      IFSCCode: ['', [Validators.required]]
    });

    this.rejectForm = this.fb.group({
      rejectionComment: ['', [Validators.required]],
      currentStep: ['', [Validators.required]]
    });

    this.applicationDetailsForm = this.fb.group({
      applicationId: ['', [Validators.required]],
      currentStep: ['', [Validators.required]],
      isFSSAISubmitted: ['', [Validators.required]],
      isGSTSubmitted: ['', [Validators.required]],
      isUdyamSubmitted: ['', [Validators.required]]
    });

    this.outletdetailsForm = this.fb.group({
      outletName: ['', [Validators.required]],
      outletCategory: ['', [Validators.required]],
      priceForTwo: ['', [Validators.required]],
      outletLocation: ['', [Validators.required]],
      outletLocality: ['', [Validators.required]]
    })

    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.userData = this.globalDataService.getUserData();
        if (!this.userData) {
          this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
            this.userData = res.data();
            this.globalDataService.setUserData(this.userData);
          })
        }
      } else {
        console.warn("User not logged in");
        // User not logged in or has just logged out.
      }
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['action'] == 'all') {
        this.getApplications()
      } else if (params['action'] == 'open') {
        // Handle 'all' param
      }
    });
    this.steps = [
      { name: '1-Become an AAharian', value: 1 },
      { name: '2-Owner Details' , value: 2},
      { name: '3-Owner Verification', value: 3 },
      { name: '4-Outlet Details', value: 4 },
      { name: '5-Outlet Verification', value: 5 },
      { name: '6-Review and Pay' , value: 6},
      { name: '7-Approval pending', value: 7 }
    ];
    this.fssai = [
      { name: 'True', value: true },
      { name: 'False', value: false },
    ];
    this.gst = [
      { name: 'True', value: true },
      { name: 'False', value: false }
    ];
    this.udyam = [
      { name: 'True', value: true },
      { name: 'False' , value: false}
    ];
  }

  ngAfterViewInit() {
    this.showAnimationContainer = true;
    this.isDataLoading = true;
    this.loadIcons();
  }

  loadIcons() {
    if (this.animationInstances.length > 0) {
      this.animationInstances.forEach((element: any) => {
        element.destroy();
      });
    }
    var timer = setInterval(() => {
      if (this.animationContainers.length > 0) {
        clearInterval(timer);
        this.animationContainers.forEach((animationContainer: ElementRef, index: number) => {
          const locationId = animationContainer.nativeElement.dataset.locationId;
          const animationType = animationContainer.nativeElement.dataset.animationType;
          const animationFilePath = this.animationFilePaths[animationType];

          this.http.get(animationFilePath).subscribe((animationData: any) => {
            const animationInstance = (lottie as any).loadAnimation({
              container: animationContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: false,
              animationData: animationData
            });

            animationInstance.setSpeed(0.5);

            const svgElement = animationContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              // console.log("SVG element found:", svgElement);
              svgElement.style.fill = '#4b5563';
            } else {
              console.error("SVG element not found for container:", animationContainer.nativeElement);
            }

            this.animationInstances[index] = animationInstance;

            animationContainer.nativeElement.addEventListener('mouseenter', () => {
              this.animationInstances[index].setDirection(1);
              this.animationInstances[index].play();
            });

            animationContainer.nativeElement.addEventListener('mouseleave', () => {
              this.animationInstances[index].setDirection(-1);
              this.animationInstances[index].play();
            });
          });
        });
        this.isDataLoading = false;
      }
    },);
  }

  loadExpansionIcons() {
    if (this.expansionAnimationInstances.length > 0) {
      this.expansionAnimationInstances.forEach((element: any) => {
        element.destroy();
      });
    }
    var timer = setInterval(() => {
      if (this.expansionContainers.length > 0) {
        clearInterval(timer);
        this.expansionContainers.forEach((expansionContainer: ElementRef, index: number) => {
          const locationId = expansionContainer.nativeElement.dataset.locationId;
          const animationType = expansionContainer.nativeElement.dataset.animationType;
          const animationFilePath = this.animationFilePaths[animationType];

          this.http.get(animationFilePath).subscribe((animationData: any) => {
            const animationInstance = (lottie as any).loadAnimation({
              container: expansionContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: false,
              animationData: animationData
            });

            animationInstance.setSpeed(0.5);

            const svgElement = expansionContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              // console.log("SVG element found:", svgElement);
              svgElement.style.fill = '#4b5563';
            } else {
              console.error("SVG element not found for container:", expansionContainer.nativeElement);
            }

            this.expansionAnimationInstances[index] = animationInstance;

            expansionContainer.nativeElement.addEventListener('mouseenter', () => {
              this.expansionAnimationInstances[index].setDirection(1);
              this.expansionAnimationInstances[index].play();
            });

            expansionContainer.nativeElement.addEventListener('mouseleave', () => {
              this.expansionAnimationInstances[index].setDirection(-1);
              this.expansionAnimationInstances[index].play();
            });
          });
        });
        this.isDataLoading = false;
      }
    },);
  }

  showDialog(owner: any, application: any) {
    this.selectedOwner = [owner];
    this.selectedApplication = [application];
    this.visible = true

  }
  DontshowDialog() {
    this.visible = false;
    this.selectedOwner = null;
    this.selectedApplication = null;
  }

  showRejectDialog(owner: any, application: any) {
    this.rejectVisible = true;
    this.selectedOwner = [owner];
    this.selectedApplication = [application];
  }

DontshowRejectDialog() {
  this.rejectVisible = false;
  this.selectedOwner = null;
  this.selectedApplication = null;
}

showDeleteDialog(owner: any, application: any) {
  this.deleteVisible = true;
  this.selectedOwner = [owner];
  this.selectedApplication = [application];
}

DontshowDeleteDialog() {
this.deleteVisible = false;
this.selectedOwner = null;
this.selectedApplication = null;
}


  showEditDialog(owner: any, application: any) {
    this.selectedOwner = [owner];
    this.selectedApplication = [application];
    this.EditVisible = true;
    console.log(owner);
    this.basicDetailsForm.patchValue({
      firstName: owner?.firstName,
      lastName: owner?.lastName,
      mobileNumber: owner?.mobile,
      emailId: owner?.email,
      ownerId: owner?.ownerId
    });
    this.basicDetailsForm.controls['ownerId'].disable();
    this.basicDetailsForm.controls['emailId'].disable();

    this.aadhaarDataForm.patchValue({
      aadhaarNumber: owner?.aadhaarData?.aadhaar_number,
      fullName: owner?.aadhaarData?.full_name,
      dateOfBirth: owner?.aadhaarData?.dob,
      gender: owner?.aadhaarData?.gender,

    });
    this.aadhaarDataForm.controls['aadhaarNumber'].disable();
    this.aadhaarDataForm.controls['fullName'].disable();
    this.aadhaarDataForm.controls['dateOfBirth'].disable();
    this.aadhaarDataForm.controls['gender'].disable();

    this.PANDataForm.patchValue({
      panNumber: owner?.panData?.pan_number,
      fullName: owner?.panData?.full_name,
      category: owner?.panData?.category

    });
    this.PANDataForm.controls['panNumber'].disable();
    this.PANDataForm.controls['fullName'].disable();
    this.PANDataForm.controls['category'].disable();

    this.bankDataForm.patchValue({
      bankAccountNumber: owner?.primaryBankAccData?.bankAccNumber,
      IFSCCode: owner?.primaryBankAccData?.bankIFSC
    });
    this.bankDataForm.controls['bankAccountNumber'].disable();
    this.bankDataForm.controls['IFSCCode'].disable();
    this.applicationDetailsForm.patchValue({
      applicationId: application?.applicationId,
      currentStep: application?.currentStep,
      isFSSAISubmitted: application?.isFSSAISubmitted,
      isGSTSubmitted: application?.isGSTSubmitted,
      isUdyamSubmitted: application?.isUdyamSubmitted
    });
    this.applicationDetailsForm.controls['applicationId'].disable();

    this.outletdetailsForm.patchValue({
      outletName: application.outletDetails?.outletName,
      outletCategory: application.outletDetails?.outletCategory,
      priceForTwo: application.outletDetails?.priceForTwo,
      outletLocation: application.outletDetails?.outletLocation,
      outletLocality: application.outletDetails?.outletLocality
    });

  }
  DontshowEditDialog() {
    this.selectedOwner = null;
    this.selectedApplication = null;
    this.EditVisible = false;
  }
  showApproveDialog(owner: any, application: any) {
    this.selectedOwner = [owner];
    this.selectedApplication = [application];
    this.ApproveVisible = true;
    console.log(owner);
    this.basicDetailsForm.patchValue({
      firstName: owner.firstName,
      lastName: owner.lastName,
      mobileNumber: owner.mobile,
      emailId: owner.email,
      ownerId: owner.ownerId
    });
    this.basicDetailsForm.controls['ownerId'].disable();
    this.basicDetailsForm.controls['emailId'].disable();

    this.aadhaarDataForm.patchValue({
      aadhaarNumber: owner.aadhaarData.aadhaar_number,
      fullName: owner.aadhaarData.full_name,
      dateOfBirth: owner.aadhaarData.dob,
      gender: owner.aadhaarData.gender,

    });
    this.aadhaarDataForm.controls['aadhaarNumber'].disable();
    this.aadhaarDataForm.controls['fullName'].disable();
    this.aadhaarDataForm.controls['dateOfBirth'].disable();
    this.aadhaarDataForm.controls['gender'].disable();

    this.PANDataForm.patchValue({
      panNumber: owner.panData.pan_number,
      fullName: owner.panData.full_name,
      category: owner.panData.category

    });
    this.PANDataForm.controls['panNumber'].disable();
    this.PANDataForm.controls['fullName'].disable();
    this.PANDataForm.controls['category'].disable();

    this.bankDataForm.patchValue({
      bankAccountNumber: owner.primaryBankAccData.bankAccNumber,
      IFSCCode: owner.primaryBankAccData.bankIFSC
    });
    this.bankDataForm.controls['bankAccountNumber'].disable();
    this.bankDataForm.controls['IFSCCode'].disable();

    this.applicationDetailsForm.patchValue({
      applicationId: application.applicationId,
      currentStep: application.currentStep,
      isFSSAISubmitted: application.isFSSAISubmitted,
      isGSTSubmitted: application.isGSTSubmitted,
      isUdyamSubmitted: application.isUdyamSubmitted
    });
    this.applicationDetailsForm.controls['applicationId'].disable();

    this.outletdetailsForm.patchValue({
      outletName: application.outletDetails?.outletName,
      outletCategory: application.outletDetails?.outletCategory,
      priceForTwo: application.outletDetails?.priceForTwo,
      outletLocation: application.outletDetails?.outletLocation,
      outletLocality: application.outletDetails?.outletLocality
    });

  }
 
  DontshowApproveDialog() {
    this.selectedOwner = null;
    this.selectedApplication = null;
    this.EditVisible = false;
  }

  getApplications(action?: string) {
    this.dataLoading = true;
    this.openApplications = []
    this.applicationOwners = []
    this.applicationService.getOpenApplicationOwners().subscribe((res: any) => {
      this.openApplications = res.data().openApplications;
      this.openApplications.forEach((docId: any) => {
        this.applicationService.getOpenApplicationDetails(docId).then((res: any) => {
          this.applicationOwners.push(res);
          this.openApplications.push(res)
        })
      });
      this.dataLoading = false;
    })
    this.cdr.detectChanges();
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.ownerId] = true;
    this.loadExpansionIcons();
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.ownerId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');
  }

  approveOutlet( application: any) {
    this.showSpinner = true;
    this.applicationService.approveOutletApplication('Eluru', 'Sanivarapupeta', application).then((res) => {
      this.toastService.showSuccess("Application approved successfully")
      var obj = {
        notificationTitle: 'Application Approved',
        notificationDescription: `${this.openApplications} is successfully approved  by ${this.userData.firstName + ' ' + this.userData.lastName}`,
        notificationTime: new Date().getTime(),
        severity: 'success'
      }
      this.globalDataService.superAdminIds.forEach((admin) => {
        this.globalDataService.sendNotification(admin, obj).then((res) => {
          // console.warn("notification sent");
        })
      })
      this.showSpinner = false;
    }, err => {
      this.showSpinner = false;
      this.toastService.showError(err);
    })

  }

  deleteApplication(owner: any, application: any) {
    this.showSpinner = true;
    this.applicationService.deleteApplication(owner, application).then((res: any) => {
      this.showSpinner = false;
      this.toastService.showSuccess("Application deleted successfully");
      var obj = {
        notificationTitle: 'Application Deleted',
        notificationDescription: `${ this.openApplications} is deleted by ${  this.userData.firstName + ' ' + this.userData.lastName}`,
        notificationTime: new Date().getTime(),
        severity: 'Danger'
      }
      this.getApplications();
      this.loadIcons();
    }, err => {
      this.showSpinner = false;
      this.toastService.showError("Error", err)
    })
  }

  deleteOwner(owner: any) {
    this.showSpinner = true;
    this.applicationService.removeOwner(owner.uid).then((res: any) => {
      this.showSpinner = false;
      this.toastService.showSuccess("Owner details deleted successfully");
      this.getApplications();
      this.loadIcons();
    }, err => {
      this.showSpinner = false;
      this.toastService.showError("Error", err)
    })
  }
}
