import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../shared/toast.service';
import { getAuth, setPersistence, browserLocalPersistence, onAuthStateChanged } from "firebase/auth"; // Import for persistence

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  isSignUp: boolean = true;
  showSpinner: boolean = false;
  showInitSpinner: boolean = true;
  showResetForm: boolean = false;
  userLoginForm: FormGroup;
  userResetForm: FormGroup;
  isPasswordVisible: boolean = false;

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  constructor(private fb: FormBuilder, private firebaseAuth: AngularFireAuth, private router: Router,
    private firestore: AngularFirestore, private toastService: ToastService, private route: ActivatedRoute) {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            this.router.navigateByUrl('/home');
            this.showInitSpinner = false;
          } else {
            this.showInitSpinner = false;
            console.log("No user is signed in.");
          }
        });
      })
      .catch((error) => {
        this.showInitSpinner = false;
        console.error('Error setting persistence:', error);
      });

    if (this.route.snapshot.paramMap.get('state') == 'disabled') {
      setTimeout(() => {
        this.toastService.showError('Account disabled', 'Contact admin to reactivate your account.')
      }, 200);
    } else if (this.route.snapshot.paramMap.get('state') == 'restricted') {
      this.toastService.showError('Not Authorised', 'Contact admin to get access to this application.')
    }
    this.userLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.userResetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }



  staffLogin() {
    this.showSpinner = true;
    if (this.userLoginForm.valid) { // Assuming you have a 'userLoginForm' FormGroup
      const email = this.userLoginForm.value.email;
      const password = this.userLoginForm.value.password;

      // Firebase Authentication user login
      this.firebaseAuth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // User login successful
          const user: any = userCredential.user;
          this.firestore.collection('staff').doc(user.uid).ref.get()
            .then((docSnapshot) => {
              if (docSnapshot.exists) {
                // User exists in staff collection, proceed to dashboard
                this.showSpinner = false;
                this.toastService.showSuccess("Loged in successfully")
                this.router.navigateByUrl('/home');
              } else {
                // User is not in staff collection, handle accordingly (e.g., show error, logout)
                console.log('User logged in but is not staff:', user);
                this.toastService.showWarn('User is not a staff member')
                this.showSpinner = false;
                this.firebaseAuth.signOut(); // Optional: Log the user out
                // Display an error message or take other appropriate action
              }
            })
            .catch((error) => {
              this.showSpinner = false;
              console.error('Error checking staff collection:', error);
              this.toastService.showError('Error checking staff collection:', error);
              // Handle Firestore error
            });
        })
        .catch((error) => {
          this.showSpinner = false;
          // Handle errors (e.g., display an error message)
          console.error('Error logging in user:', error);
          if (error == 'FirebaseError: Firebase: The supplied auth credential is incorrect, malformed or has expired. (auth/invalid-credential).') {
            this.toastService.showError('Error logging in user: invalid credentials')
          } else {
            this.toastService.showError('Error logging in user: please try again after some time')
          }
        });
    } else {
      this.showSpinner = false;
      // Handle invalid form submission (e.g., mark fields as touched to show errors)
      this.userLoginForm.markAllAsTouched();
    }
  }

  toggleReset() {
    this.showResetForm = !this.showResetForm;
  }

  async initReset() {
    this.showSpinner = true;
    try {
      await this.firebaseAuth.sendPasswordResetEmail(this.userResetForm.value.email);
      this.toastService.showSuccess('Password reset email sent successfully!');
      this.showSpinner = false;
    } catch (error) {
      this.toastService.showError('Error sending password reset email');
      this.showSpinner = false;
    }
  }
}