import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { catchError, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { deleteObject, getStorage, listAll, ref } from 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  staffCollection: AngularFirestoreCollection;
  ownersCollection: AngularFirestoreCollection;
  outletsCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore, private http: HttpClient) { 
    this.staffCollection = fireStore.collection('staff');
    this.ownersCollection = fireStore.collection('restaurantOwners');
    this.outletsCollection = fireStore.collection('outlets');
  }

  getOpenApplicationOwners() {
    return this.staffCollection.doc('applications').get();
  }

  async getOpenApplicationDetails(docId: any) {
    try {
      const docSnapshot = await this.ownersCollection.doc(docId).ref.get();
      if (docSnapshot.exists) {
        const data: any = docSnapshot.data();
        const { firstName, lastName, ownerId, email, mobile, openOutletApplications } = data; 
        return docSnapshot.data(); 
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      return error
      // Handle the error appropriately
    }
  }

  approveOutletApplication(location: string, locality: string, obj: any) {
    return this.outletsCollection.doc(location).collection(locality).doc().set(obj);
  }

  async deleteApplication(owner: any, application: any): Promise<void> {
    try {
      const querySnapshot = await this.ownersCollection.ref.where('ownerId', '==', owner.ownerId).get();
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        const docData: any = querySnapshot.docs[0].data();
        if (docData && Array.isArray(docData.openOutletApplications)) {
          const index = docData.openOutletApplications.findIndex(
            (item: any) => item.applicationId === application.applicationId
          );
          if (index !== -1) {
            docData.openOutletApplications.splice(index, 1);
            await docRef.update({ openOutletApplications: docData.openOutletApplications });
            console.log('Application deleted successfully');
          } else {
            console.warn('Application not found in openOutletApplications array');
          }
        } else {
          console.warn('No openOutletApplications array found in the document');
        }
      } else {
        console.warn('Owner document not found');
      }
    } catch (error) {
      console.error('Error deleting application:', error);
    }
  }

  async deleteOwner(owner: any): Promise<void> {
    try {
      const querySnapshot = await this.ownersCollection.ref.where('ownerId', '==', owner.ownerId).get();
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        const docId = querySnapshot.docs[0].id;
        await docRef.delete();
        console.log(`Owner with ownerId ${owner.ownerId} and docId ${docId} deleted successfully.`);
        const applicationsDocRef = this.staffCollection.doc('applications');
        const applicationsDoc: any = await applicationsDocRef.get().toPromise();
        if (applicationsDoc.exists) {
          const docData: any = applicationsDoc.data();
          if (docData && Array.isArray(docData.openApplications)) {
            const index = docData.openApplications.findIndex((appId: string) => appId === docId);
            if (index !== -1) {
              docData.openApplications.splice(index, 1);
              await applicationsDocRef.update({ openApplications: docData.openApplications });
              console.log(`Removed docId ${docId} from openApplications array.`);
            } else {
              console.warn(`DocId ${docId} not found in openApplications array.`);
            }
          } else {
            console.warn(`No openApplications array found in the applications document.`);
          }
        } else {
          console.warn('Applications document does not exist in staffCollection.');
        }
      } else {
        console.warn(`No document found for ownerId ${owner.ownerId}.`);
      }
    } catch (error) {
      console.error('Error deleting owner and updating openApplications:', error);
    }
  }
  
    async removeOwner(uid: string): Promise<void> {
      try {
        // Call the Cloud Function via its HTTPS endpoint
        const result = await lastValueFrom(
          this.http.post<{ success?: boolean; error?: string }>(environment.cloudFunctionURL+'/deleteUser', { uid }).pipe(
            catchError((error) => {
              console.error('Error calling deleteUser endpoint:', error);
              throw new Error('Failed to delete user via Cloud Function.');
            })
          )
        );
  
        if (result?.success) {
          await this.ownersCollection.doc(uid).delete();
          console.log(`User with UID ${uid} removed from Firestore.`);
          const storage = getStorage(); 
          const storageRef = ref(storage);
          const folderRef = ref(storage, `onBoarding/${uid}`); 
          await listAll(folderRef).then(async (res) => { // Use listAll()
            // Delete all files within the folder
            for (let itemRef of res.items) {
              await deleteObject(itemRef); // Use deleteObject()
            }
            // Delete the folder itself (empty folders are automatically deleted)
          });
          console.log(`Folder onBoarding/${uid} removed from Storage.`);
        } else {
          // Handle the error returned by the Cloud Function
          console.error(`Cloud Function error: ${result?.error}`);
          throw new Error(result?.error || 'Failed to delete user.');
        }
      } catch (error) {
        console.error('Error removing staff:', error);
        throw error; // Rethrow the error for further handling
      }
    }
  
}
