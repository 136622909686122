import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StaffService } from '../../services/staff.service';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrl: './title-bar.component.scss'
})
export class TitleBarComponent {

  @Input() activeNav: string = '';
  activeAction: string | null = null;
  staffDetails: any;
  notifications: any[] = [];

  constructor(private activatedRoute: ActivatedRoute, private staffService: StaffService) {
    let startTime = new Date(); // Capture the start time
    const timer = setInterval(() => {
      if (this.staffService.staffDetails) {
        clearInterval(timer);
        this.staffDetails = this.staffService.staffDetails;
        
        let previousNotificationsLength = 0; // Track the length of the previous notifications array
  
        this.staffService.staffCollection
          .doc(this.staffDetails.uid)
          .collection('notifications')
          .valueChanges({ idField: 'id' })
          .subscribe((notifications: any) => {
            // Play notification sound if new notifications are received
            if (notifications.length > previousNotificationsLength) {
              this.playNotificationSound();
            }
  
            // Update notifications and previous length
            this.notifications = this.sortedNotifications(notifications);
            
            previousNotificationsLength = notifications.length;
          });
      } else {
        // Calculate elapsed time in milliseconds
        let elapsedTime = new Date().getTime() - startTime.getTime();
  
        // If elapsed time exceeds 1 minute (60000 milliseconds), clear the interval
        if (elapsedTime > 60000) {
          clearInterval(timer);
        }
      }
    });
  }

  sortedNotifications(notifications: any) {
    return notifications.sort((a: any, b: any) => b.notificationTime - a.notificationTime);
  }
  
  // Method to play the notification sound
  private playNotificationSound(): void {
    const audio = new Audio('assets/sounds/notificationBell.mp3');
    audio.play().catch((error) => console.error('Error playing notification sound:', error));
  }
  

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });
  }

  clearNotification(notificationId: any, event: any, notificationPanel: any) {
    this.staffService.clearNotification(notificationId).then((res) => {
    })
  }

  clearAllNotifications(notificationPanel: any) {
    this.staffService.clearAllNotifications().then((res) => {
      if (this.notifications.length === 0) {
        notificationPanel.toggle(event);
      }
    })
  }

}
