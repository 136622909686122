import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocationsService } from '../../services/locations.service';
import firebase from 'firebase/compat/app';
import { ToastService } from '../../shared/toast.service';
import { GlobalDataService } from '../../services/global-data.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import * as lottie from 'lottie-web';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrl: './locations.component.scss'
})
export class LocationsComponent {

  locations: any[] = [];
  showAddLocationModal: boolean = false;
  showAddLocalityModal: boolean = false;
  showRejectLocationModal: boolean = false;
  showRejectLocalityModal: boolean = false;
  showStatusConfirmationModal: boolean = false;
  showLocalityStatusConfirmationModal: boolean = false;
  showDeleteConfirmationModal: boolean = false;
  showDeleteLocalityConfirmationModal: boolean = false;
  isDataLoading: boolean = false;
  isUpdateLocation: boolean = false;
  isUpdateLocality: boolean = false;
  showSpinner: boolean = false;
  addLocationForm: FormGroup;
  addLocalityForm: FormGroup;
  rejectLocationForm: FormGroup;
  rejectLocalityForm: FormGroup;
  user: any;
  userData: any;
  expandedRows: any = {};
  selectedLocation: any;
  selectedLocality: any;
  selectedLocalityId: any;
  activeAction: any;
  @ViewChildren('animationContainer') animationContainers!: QueryList<ElementRef>;
  @ViewChildren('expansionContainer') expansionContainers!: QueryList<ElementRef>;
  animationInstance: any;
  animationInstances: any[] = [];
  showAnimationContainer = false;
  expansionAnimationInstances: any[] = [];

  // Map of animation container IDs to their respective animation file paths
  animationFilePaths: { [key: string]: string } = {
    trash: 'assets/animIcons/trashIcon.json',
    edit: 'assets/animIcons/penIcon.json',
    pause: 'assets/animIcons/pauseIcon.json',
    add: 'assets/animIcons/plusIcon.json',
    check: 'assets/animIcons/checkIcon.json',
    reject: 'assets/animIcons/rejectIcon.json',
    reload: 'assets/animIcons/reloadIcon.json',
  };

  constructor(private fb: FormBuilder, private locationService: LocationsService,
    private toastService: ToastService, private globalDataService: GlobalDataService, private authService: AuthService,
    private activatedRoute: ActivatedRoute, private http: HttpClient) {
    this.addLocationForm = this.fb.group({
      locationId: ['', [Validators.required, Validators.pattern('^[0-9]{2}$')]],
      locationName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]+$')]],
    });
    this.addLocalityForm = this.fb.group({
      localityId: ['', [Validators.required, Validators.pattern('^[0-9]{2}$')]],
      localityName: ['', Validators.required],
    });
    this.rejectLocationForm = this.fb.group({
      rejectionComments: ['', [Validators.required]],
    });
    this.rejectLocalityForm = this.fb.group({
      rejectionComments: ['', [Validators.required]],
    });
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.userData = this.globalDataService.getUserData();
        if (!this.userData) {
          this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
            this.userData = res.data();
            this.globalDataService.setUserData(this.userData);
          })
        }
      } else {
        console.warn("User not logged in");
        // User not logged in or has just logged out.
      }
    });
    // this.getLocations(params['action']);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action']
        ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
        : undefined;
      this.getLocations(params['action'])
    });
  }

  ngAfterViewInit() {
    this.showAnimationContainer = true;
    this.isDataLoading = true;
    this.loadIcons();
  }

  loadIcons() {
    if (this.animationInstances.length > 0) {
      this.animationInstances.forEach((element: any) => {
        element.destroy();
      });
    }
    var timer = setInterval(() => {
      if (this.animationContainers.length > 0) {
        clearInterval(timer);
        this.animationContainers.forEach((animationContainer: ElementRef, index: number) => {
          const locationId = animationContainer.nativeElement.dataset.locationId;
          const animationType = animationContainer.nativeElement.dataset.animationType;
          const animationFilePath = this.animationFilePaths[animationType];

          this.http.get(animationFilePath).subscribe((animationData: any) => {
            const animationInstance = (lottie as any).loadAnimation({
              container: animationContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: false,
              animationData: animationData
            });

            animationInstance.setSpeed(0.5);

            const svgElement = animationContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              // console.log("SVG element found:", svgElement);
              svgElement.style.fill = '#4b5563';
            } else {
              console.error("SVG element not found for container:", animationContainer.nativeElement);
            }

            this.animationInstances[index] = animationInstance;

            animationContainer.nativeElement.addEventListener('mouseenter', () => {
              this.animationInstances[index].setDirection(1);
              this.animationInstances[index].play();
            });

            animationContainer.nativeElement.addEventListener('mouseleave', () => {
              this.animationInstances[index].setDirection(-1);
              this.animationInstances[index].play();
            });
          });
        });
        this.isDataLoading = false;
      }
    },);
  }

  loadExpansionIcons() {
    if (this.expansionAnimationInstances.length > 0) {
      this.expansionAnimationInstances.forEach((element: any) => {
        element.destroy();
      });
    }
    var timer = setInterval(() => {
      if (this.expansionContainers.length > 0) {
        clearInterval(timer);
        this.expansionContainers.forEach((expansionContainer: ElementRef, index: number) => {
          const locationId = expansionContainer.nativeElement.dataset.locationId;
          const animationType = expansionContainer.nativeElement.dataset.animationType;
          const animationFilePath = this.animationFilePaths[animationType];

          this.http.get(animationFilePath).subscribe((animationData: any) => {
            const animationInstance = (lottie as any).loadAnimation({
              container: expansionContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: false,
              animationData: animationData
            });

            animationInstance.setSpeed(0.5);

            const svgElement = expansionContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              // console.log("SVG element found:", svgElement);
              svgElement.style.fill = '#4b5563';
            } else {
              console.error("SVG element not found for container:", expansionContainer.nativeElement);
            }

            this.expansionAnimationInstances[index] = animationInstance;

            expansionContainer.nativeElement.addEventListener('mouseenter', () => {
              this.expansionAnimationInstances[index].setDirection(1);
              this.expansionAnimationInstances[index].play();
            });

            expansionContainer.nativeElement.addEventListener('mouseleave', () => {
              this.expansionAnimationInstances[index].setDirection(-1);
              this.expansionAnimationInstances[index].play();
            });
          });
        });
        this.isDataLoading = false;
      }
    }, 10);
  }

  getLocations(status: any) { // Add optional 'status' parameter
    this.locations = [];
    this.isDataLoading = true;

    this.locationService.getLocationData().subscribe((res: any[]) => {
      res.forEach((location) => {
        if (status === 'all' || location.status.toLowerCase() === status.toLowerCase()) { // Filter based on status
          this.locations.push(location);
        }
      });

      // Sort after filtering
      this.locations.sort((a, b) => parseInt(a.locationId) - parseInt(b.locationId));

      this.isDataLoading = false;
    }, err => {
      console.warn(err);
      this.isDataLoading = false;
    });
  }

  toggleAddLocationModal(location?: any, isClose?: boolean) {
    if (isClose) {
      this.showAddLocationModal = false;
      this.addLocationForm.reset();
      this.addLocationForm.controls['locationId'].enable();
    } else {
      this.showAddLocationModal = !this.showAddLocationModal;
    }
    if (!this.showAddLocationModal) {
      this.addLocationForm.reset()
      this.isUpdateLocation = false;
      this.selectedLocation = null;
    } else {
      if (location) {
        this.isUpdateLocation = true;
        this.selectedLocation = location;
        this.showSpinner = true;
        this.locationService.getActiveLocation(this.selectedLocation.id).subscribe((res: any) => {
          this.addLocationForm.patchValue({
            locationName: res.data().locationName,
            locationId: res.data().locationId
          });
          this.addLocationForm.controls['locationId'].disable();
          this.showSpinner = false;
        })
      } else {
        this.isUpdateLocation = false;
        this.selectedLocation = null;
      }
    }
  }

  toggleAddLocalityModal(location?: any, locality?: any) {
    this.showAddLocalityModal = !this.showAddLocalityModal;
    if (this.showAddLocalityModal) {
      this.showSpinner = true;
      this.selectedLocation = location;
      if (locality?.localityId) {
        this.isUpdateLocality = true;
        this.selectedLocality = locality;
        this.selectedLocalityId = locality.localityId;
        const matchingLocality = this.selectedLocation.localities.find((local: any) => local.localityId === locality.localityId);
        this.addLocalityForm.patchValue({
          localityName: matchingLocality.localityName,
          localityId: matchingLocality.localityId
        });
        this.addLocalityForm.controls['localityId'].disable();
      } else {
        this.isUpdateLocality = false;
        this.selectedLocalityId = null;
        this.addLocalityForm.controls['localityId'].enable();
        this.addLocalityForm.reset();
      }
      this.showSpinner = false;
    } else {
      this.selectedLocation = null;
      this.isUpdateLocality = false;
      this.showAddLocalityModal = false;
      this.addLocalityForm.controls['localityId'].enable();
      this.addLocalityForm.reset();
    }
  }

  registerNewLocation(locationName?: any, isReSubmit?: boolean) {
    if (this.isUpdateLocation) {
      var updateObj = {}
      if (isReSubmit) {
        updateObj = {
          status: 'pending',
          locationId: this.selectedLocation.locationId,
          locationName: this.addLocationForm.value.locationName,
          lastUpdatedAt: new Date().getTime(),
          lastUpdatedById: this.user.uid,
          lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName
        };
      } else {
        updateObj = {
          status: 'pending',
          locationId: this.selectedLocation.locationId,
          locationName: this.selectedLocation.locationName,
          newLocationName: this.addLocationForm.value.locationName,
          lastUpdatedAt: new Date().getTime(),
          lastUpdatedById: this.user.uid,
          lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName
        };
      }

      this.showSpinner = true;
      this.locationService.updateLocation(this.selectedLocation.id, updateObj, isReSubmit)
        .then((result) => {
          if (result === 4) {
            this.toastService.showSuccess('Location updated successfully');
            var obj = {
              notificationTitle: 'Location Updated',
              notificationDescription: `${this.selectedLocation.locationName} is updated to ${this.addLocationForm.value.locationName} by ${this.userData.firstName + ' ' + this.userData.lastName}`,
              notificationTime: new Date().getTime(),
              severity: 'success'
            }
            this.globalDataService.superAdminIds.forEach((admin) => {
              this.globalDataService.sendNotification(admin, obj).then((res) => {
                // console.warn("notification sent");
              })
            })
            this.showSpinner = false;
            this.toggleAddLocationModal();
            this.getLocations(this.activeAction.toLowerCase()); // Refresh the locations data
            this.loadIcons();
          } else if (result === 2) {
            this.showSpinner = false;
            this.toastService.showError('Location Name already exists.');
          }
        })
        .catch(error => {
          this.toastService.showError('Error updating location');
          this.showSpinner = false;
        });
    } else {
      var obj = {
        locationId: this.addLocationForm.value.locationId,
        locationName: this.addLocationForm.value.locationName,
        createdAt: new Date().getTime(),
        createdById: this.user.uid,
        createdBy: this.userData.firstName + ' ' + this.userData.lastName,
        status: 'pending',
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName
      };
      this.showSpinner = true;
      this.locationService.addNewLocation(obj).then((res) => {
        if (res === 1) { // Check for null response
          this.toastService.showError(`${this.addLocationForm.value.locationName}, Location Id already exists.`);
          this.showSpinner = false;
        } else if (res === 2) {
          this.toastService.showError(`${this.addLocationForm.value.locationName}, Location Name already exists.`);
          this.showSpinner = false;
        } else if (res === 3) {
          this.toastService.showError(`${this.addLocationForm.value.locationName}, Location Id and Location Name already exists.`);
          this.showSpinner = false;
        } else {
          this.toastService.showSuccess(`${this.addLocationForm.value.locationName}, Location added successfully`);
          var obj = {
            notificationTitle: 'Added new location',
            notificationDescription: `${this.addLocationForm.value.locationName} is succesfully added by ${this.userData.firstName + ' ' + this.userData.lastName}`,
            notificationTime: new Date().getTime(),
            severity: 'success'
          }
          this.globalDataService.superAdminIds.forEach((admin) => {
            this.globalDataService.sendNotification(admin, obj).then((res) => {
              // console.warn("notification sent");
            })
          })
          this.toggleAddLocationModal();
          this.showSpinner = false;
          this.getLocations(this.activeAction.toLowerCase());
          this.loadIcons();
        }
      }, (err: any) => {
        this.showSpinner = false;
        this.toastService.showError(`${this.addLocationForm.value.locationName}, error while adding Location`)
      })
    }
  }

  registerNewLocality(isReSubmit?: boolean) {
    if (this.isUpdateLocality) {
      var updateObj = {}
      if (isReSubmit) {
        updateObj = {
          localityId: this.addLocalityForm.get('localityId')?.getRawValue(),
          localityName: this.addLocalityForm.value.localityName,
          lastUpdatedAt: new Date().getTime(),
          lastUpdatedById: this.user.uid,
          lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
          status: 'pending'
        };
      } else {
        updateObj = {
          localityId: this.addLocalityForm.get('localityId')?.getRawValue(),
          newLocalityName: this.addLocalityForm.value.localityName,
          lastUpdatedAt: new Date().getTime(),
          lastUpdatedById: this.user.uid,
          lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
          status: 'pending'
        };
      }
      this.showSpinner = true;
      this.locationService.updateLocality(this.selectedLocation.id, this.addLocalityForm.get('localityId')?.getRawValue(), updateObj, isReSubmit)
        .then((result: any) => {
          this.toastService.showSuccess('Locality updated successfully');
          var obj = {
            notificationTitle: 'Locality Updated',
            notificationDescription: `${this.addLocalityForm.get('localityId')?.getRawValue()} is updated to ${this.addLocalityForm.value.localityName} by ${this.userData.firstName + ' ' + this.userData.lastName} in ${this.selectedLocation.locationName}`,
            notificationTime: new Date().getTime(),
            severity: 'success'
          }
          this.globalDataService.superAdminIds.forEach((admin) => {
            this.globalDataService.sendNotification(admin, obj).then((res) => {
              // console.warn("notification sent");
            })
          })
          this.toggleAddLocalityModal()
          setTimeout(() => {
            this.getLocations(this.activeAction.toLowerCase());
            this.loadIcons();
            this.showSpinner = false;
          }, 1000);
        })
        .catch(error => {
          console.error('Error updating locality:', error);
          this.toastService.showError('Error updating locality');
        });
    } else {
      var obj = {
        localityId: this.addLocalityForm.value.localityId,
        localityName: this.addLocalityForm.value.localityName,
        createdAt: new Date().getTime(),
        createdById: this.user.uid,
        createdBy: this.userData.firstName + ' ' + this.userData.lastName,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
        status: 'pending'
      };
      if (this.selectedLocation.localities) {
        const existingLocality = this.selectedLocation.localities.find(
          (locality: any) =>
            locality.localityId === this.addLocalityForm.value.localityId ||
            locality.localityName === this.addLocalityForm.value.localityName
        );

        if (existingLocality) {
          this.toastService.showError('Locality ID or Locality Name already exists within this location.')
        } else {
          this.showSpinner = true;
          this.locationService.addNewLocality(this.selectedLocation.id, [obj]).then((res) => {
            this.toastService.showSuccess("Locality added successfully")
            var obj = {
              notificationTitle: 'Added new locality',
              notificationDescription: `${this.addLocalityForm.value.localityName} is successfully added by ${this.userData.firstName + ' ' + this.userData.lastName} in ${this.selectedLocation.locationName}`,
              notificationTime: new Date().getTime(),
              severity: 'success'
            }
            this.globalDataService.superAdminIds.forEach((admin) => {
              this.globalDataService.sendNotification(admin, obj).then((res) => {
                // console.warn("notification sent");
              })
            })
            this.toggleAddLocalityModal();
            this.getLocations(this.activeAction.toLowerCase());
            this.loadIcons();
            this.showSpinner = false;
          }, err => {
            this.showSpinner = false;
          });
        }
      } else {
        var arr = [];
        arr.push(obj)
        this.showSpinner = true;
        this.locationService.addNewLocality(this.selectedLocation.id, arr).then((res) => {
          this.toastService.showSuccess("Locality addedd successfully")
          this.toggleAddLocalityModal();
          this.getLocations(this.activeAction.toLowerCase());
          this.loadIcons();
          this.showSpinner = false;
        }, err => {
          this.showSpinner = false;
        })
      }
    }
  }

  deleteLocation(docId: any) {
    this.showSpinner = true;
    this.locationService.deleteLocation(docId).then((res: any) => {
      this.toastService.showSuccess('Location deleted successfully');
      var obj = {
        notificationTitle: 'Location Deleted',
        notificationDescription: `${this.selectedLocation.locationName} is deleted by ${this.userData.firstName + ' ' + this.userData.lastName}`,
        notificationTime: new Date().getTime(),
        severity: 'danger'
      }
      this.globalDataService.superAdminIds.forEach((admin) => {
        this.globalDataService.sendNotification(admin, obj).then((res) => {
          // console.warn("notification sent");
        })
      })
      this.getLocations(this.activeAction.toLowerCase());
      this.loadIcons();
      this.showSpinner = false;
      this.showDeleteConfirmationModal = false;
    })
  }

  deleteLocality(locationDocId: any, localityId: string, localityName: string) {
    const locationToDelete = this.locations.find(location => location.id === locationDocId);

    if (locationToDelete) {
      const localityIndex = locationToDelete.localities.findIndex((locality: any) => locality.localityId === localityId);

      if (localityIndex > -1) {
        locationToDelete.localities.splice(localityIndex, 1); // Remove the locality from the array
        console.log("Removed locality:", localityId, "from location:", locationDocId);

        this.locationService.deleteLocality(locationDocId, { localities: locationToDelete.localities })
          .then(() => {
            this.toastService.showSuccess('Locality deleted successfully');
            var obj = {
              notificationTitle: 'Locality Deleted',
              notificationDescription: `${this.selectedLocality.localityName} is deleted by ${this.userData.firstName + ' ' + this.userData.lastName} from ${this.selectedLocation.locationName}`,
              notificationTime: new Date().getTime(),
              severity: 'danger'
            }
            this.globalDataService.superAdminIds.forEach((admin) => {
              this.globalDataService.sendNotification(admin, obj).then((res) => {
                // console.warn("notification sent");
              })
            })
            this.getLocations(this.activeAction.toLowerCase()); // Refresh the locations data
            this.loadIcons();
            this.showRejectLocalityModal = false;
            this.showDeleteLocalityConfirmationModal = false;
          })
          .catch((error: any) => {
            console.error('Error deleting locality:', error);
            this.toastService.showError('Error deleting locality');
          });

      } else {
        console.error("Locality not found with ID:", localityId, "within location:", locationDocId);
      }
    } else {
      console.error("Location not found with ID:", locationDocId);
    }
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.ownerId] = true;
    this.loadExpansionIcons();
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.ownerId];
  }

  updateLocationStatus(location: any, locationStatus: string, isUpdateRejected?: boolean) {
    this.showSpinner = true;
    let updateData: any = {
      status: locationStatus,
      lastUpdatedAt: new Date().getTime(),
      lastUpdatedById: this.user.uid,
      lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
    };

    if (locationStatus === 'rejected') {
      updateData['rejectionComments'] = this.rejectLocationForm.value.rejectionComments;
    }
    this.locationService.updateLocationStatus(location.id, updateData, isUpdateRejected) // Or 'inactive', etc.
      .then((result) => {
        if (result === 4) {
          this.toastService.showSuccess('Location status updated successfully');
          var obj = {
            notificationTitle: 'Location status updated',
            notificationDescription: `Status is successfully updated by ${this.userData.firstName + ' ' + this.userData.lastName} of ${this.selectedLocation.locationName} `,
            notificationTime: new Date().getTime(),
            severity: 'success'
          }
          this.globalDataService.superAdminIds.forEach((admin) => {
            this.globalDataService.sendNotification(admin, obj).then((res) => {
              // console.warn("notification sent");
            })
          })
          this.showSpinner = false;
          if (isUpdateRejected) {
            const emailHtml = `
          <!DOCTYPE html>
          <html>
          <head>
            <title>Update Rejected</title>
          </head>
          <body>
            <h1>Hello ${location.lastUpdatedBy}!</h1>
            <p>This is to inform that you request to update location name from <strong>${location.locationName}</strong> to <strong>${location.newLocationName}</strong> has been rejected because of the following reason:</p> <br>
            <p>${this.rejectLocationForm.value.rejectionComments}</p>
          </body>
          </html>
        `;
            this.locationService.getUserEmail(location.lastUpdatedById).subscribe((res: any) => {
              this.locationService.sendEmail([res.data().email], 'Update request rejected', emailHtml).subscribe((res: any) => {
                console.warn(res);
              }, err => {
                console.warn(err);
              })
            })
          }
          this.getLocations(this.activeAction.toLowerCase()); // Refresh the locations data
          this.loadIcons();
          this.showStatusConfirmationModal = false;
          this.showRejectLocationModal = false;
        } else {
          // Handle other potential error codes if needed
          this.showSpinner = false;
          this.showRejectLocationModal = false;
          this.showStatusConfirmationModal = false;
          this.toastService.showError('Error updating location status');
        }
      })
      .catch(error => {
        this.showSpinner = false;
        this.showStatusConfirmationModal = false;
        console.error('Error updating location status:', error);
        this.toastService.showError('Error updating location status');
      });
  }

  updateLocalityStatus(locationDocId: any, localityId: string, localityStatus: string, isUpdateRejected?: boolean) {
    this.showSpinner = true;
    let updateData: any = {
      status: localityStatus,
    };

    if (localityStatus === 'rejected') {
      updateData['rejectionComments'] = this.rejectLocalityForm.value.rejectionComments;
    }

    // Find the location object within this.locations
    const locationToUpdate = this.locations.find(location => location.id === locationDocId);

    if (locationToUpdate) {
      // Find the index of the locality to update within the localities array
      const localityIndex = locationToUpdate.localities.findIndex((locality: any) => locality.localityId === localityId);

      if (localityIndex > -1) {
        // Update the locality's status (and rejectionComments if applicable)
        locationToUpdate.localities[localityIndex].status = localityStatus;
        if (localityStatus === 'rejected') {
          locationToUpdate.localities[localityIndex].rejectionComments = updateData['rejectionComments'];
        }

        // Update the Firestore document with the modified localities array
        this.locationService.updateLocalityStatus(locationDocId, this.selectedLocality.localityId, localityStatus, new Date().getTime(), this.user.uid, this.userData.firstName + ' ' + this.userData.lastName, localityStatus == 'rejected' ? updateData['rejectionComments'] : null, isUpdateRejected)
          .then(() => {
            this.toastService.showSuccess('Locality status updated successfully');
            var obj = {
              notificationTitle: 'Locality status updated',
              notificationDescription: `Status is successfully updated by ${this.userData.firstName + ' ' + this.userData.lastName} of ${this.selectedLocality.localityName}`,
              notificationTime: new Date().getTime(),
              severity: 'success'
            }
            this.globalDataService.superAdminIds.forEach((admin) => {
              this.globalDataService.sendNotification(admin, obj).then((res) => {
                // console.warn("notification sent");
              })
            })
            this.getLocations(this.activeAction.toLowerCase()); // Refresh the locations data
            this.loadIcons();
            this.showLocalityStatusConfirmationModal = false;
            this.showRejectLocalityModal = false;
            this.showSpinner = false;
            if (isUpdateRejected) {
              const emailHtml = `
              <!DOCTYPE html>
              <html>
              <head>
                <title>Update Rejected</title>
              </head>
              <body>
                <h1>Hello ${this.selectedLocality.lastUpdatedBy}!</h1>
                <p>This is to inform that you request to update locality name from <strong>${this.selectedLocality.localityName}</strong> to <strong>${this.selectedLocality.newLocalityName}</strong> has been rejected because of the following reason:</p> <br>
                <p>${this.rejectLocalityForm.value.rejectionComments}</p>
              </body>
              </html>
            `;
              this.locationService.getUserEmail(this.user.uid).subscribe((res: any) => {
                this.locationService.sendEmail([res.data().email], 'Update request rejected', emailHtml).subscribe((res: any) => {
                  console.warn(res);
                }, err => {
                  console.warn(err);
                })
              })
            }
          })
          .catch(error => {
            console.error('Error updating locality status:', error);
            this.toastService.showError('Error updating locality status');
            this.showSpinner = false;
            this.showLocalityStatusConfirmationModal = false;
            this.showRejectLocationModal = false;
          });
      } else {
        console.error("Locality not found with ID:", localityId, "within location:", locationDocId);
        this.showSpinner = false;
        this.showLocalityStatusConfirmationModal = false;
        this.showRejectLocationModal = false;
      }
    } else {
      console.error("Location not found with ID:", locationDocId);
      this.showSpinner = false;
      this.showLocalityStatusConfirmationModal = false;
      this.showRejectLocationModal = false;
    }
  }

  toggleRejectLocation(location: any, isClose?: boolean) {
    if (isClose) {
      this.showRejectLocationModal = false;
      this.rejectLocationForm.reset();
    } else {
      this.showRejectLocationModal = !this.showRejectLocationModal;
    }
    if (this.showRejectLocationModal) {
      this.selectedLocation = location;
    } else {
      this.selectedLocation = null;
    }
  }

  toggleRejectLocality(location: any, locality: any, isClose?: boolean) {
    if (isClose) {
      this.showRejectLocalityModal = false;
      this.rejectLocalityForm.reset();
    } else {
      this.showRejectLocalityModal = !this.showRejectLocalityModal;
    }
    if (this.showRejectLocalityModal) {
      this.selectedLocation = location;
      this.selectedLocality = locality;
    } else {
      this.selectedLocation = null;
    }
  }

  toggleUpdateStatusConfirmation(location: any) {
    this.showStatusConfirmationModal = !this.showStatusConfirmationModal;
    if (this.showStatusConfirmationModal) {
      this.selectedLocation = location;
    } else {
      this.selectedLocation = null;
    }
  }

  toggleUpdateLocalityStatusConfirmation(location: any, locality: any) {
    this.showLocalityStatusConfirmationModal = !this.showLocalityStatusConfirmationModal;
    if (this.showLocalityStatusConfirmationModal) {
      this.selectedLocation = location;
      this.selectedLocality = locality;
    } else {
      this.selectedLocation = null;
    }
  }

  toggleDeleteLocationConfirmation(location: any) {
    this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal;
    if (this.showDeleteConfirmationModal) {
      this.selectedLocation = location;
    } else {
      this.selectedLocation = null;
    }
  }

  toggleDeleteLocalityConfirmation(location: any, locality: any) {
    this.showDeleteLocalityConfirmationModal = !this.showDeleteLocalityConfirmationModal;
    if (this.showDeleteLocalityConfirmationModal) {
      this.selectedLocation = location;
      this.selectedLocality = locality;
    } else {
      this.selectedLocation = null;
    }
  }
}
