import { Component, OnInit, PLATFORM_ID, ChangeDetectorRef, inject, effect } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { LocalNotificationService } from '../../shared/local-notification.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  data: any;
  options: any;
  platformId = inject(PLATFORM_ID);
  filteredData: any[] = []; // Initialize as an empty array
  isOrdersPage = true; // Flag to track the current page
  isOutletsPage = true; // Flag to track the current page
  isFinancePage = true; // Flag to track the current page
  isApplicationsPage = true; // Flag to track the current page
  activeAction: any;
  activePage: string = 'orders'; // Active page identifier

  constructor(private localNotification: LocalNotificationService, private cd: ChangeDetectorRef, private activatedRoute: ActivatedRoute) { }

  // todayData = [
  //   {
  //     orders: '21',
  //     inProgress: 5,
  //     pending: 2,
  //     delivered: 15,
  //     canceled: 1,
  //     sales: 1200,
  //     trendPoints: [0, 10, 5, 15, 10, 20], // Trend points for graph
  //     outlets: 20,
  //     active: 5,
  //     offline: 1,
  //     disabled: 12,
  //     totalRevenue: 5000,
  //     netProfit: 3000
  //   },
  //   {
  //     orders: '15',
  //     inProgress: 3,
  //     pending: 4,
  //     delivered: 12,
  //     canceled: 0,
  //     sales: 950,
  //     trendPoints: [10, 5, 15, 20, 25, 30], // Trend points for graph
  //     outlets: 25,
  //     active: 7,
  //     offline: 1,
  //     disabled: 10,
  //     totalRevenue: 5000,
  //     netProfit: 3000
  //   }
  // ];

  // monthData = [
  //   {
  //     orders: '32',
  //     inProgress: 10,
  //     pending: 5,
  //     delivered: 20,
  //     canceled: 2,
  //     sales: 6000,
  //     trendPoints: [5, 15, 10, 20, 15, 25],
  //     outlets: 25,
  //     active: 7,
  //     offline: 1,
  //     disabled: 10,
  //     totalRevenue: 5000,
  //     netProfit: 3000
  //   },
  //   {
  //     orders: '40',
  //     inProgress: 15,
  //     pending: 3,
  //     delivered: 25,
  //     canceled: 0,
  //     sales: 3000,
  //     trendPoints: [10, 20, 15, 25, 20, 30],
  //     outlets: 19,
  //     active: 7,
  //     offline: 1,
  //     disabled: 10,
  //     totalRevenue: 5000,
  //     netProfit: 3000
  //   }
  // ];

  todayData = [
    {
      orders: '21',
      inProgress: 5,
      pending: 2,
      delivered: 15,
      canceled: 1,
      sales: 1200,
      orderTrendPoints: [0, 10, 5, 15, 10, 20],
    },
    {
      outlets: 20,
      active: 15,
      offline: 3,
      disabled: 2,
      outletSales: 1000,
      outletTrendPoints: [5, 15, 10, 20, 25, 30],
    },
    {
      totalRevenue: 5000,
      netProfit: 3000,
      revenueTrendPoints: [2000, 2500, 3000, 4000, 4500, 5000],
    },
    {
      totalApplications: 50,
      activeApplications: 35,
      pendingApplications: 10,
      rejectedApplications: 5,
      applicationTrendPoints: [10, 20, 30, 40, 50, 60],
    }
  ];

  monthData = [
    {
      orders: '120',
      inProgress: 30,
      pending: 10,
      delivered: 75,
      canceled: 5,
      sales: 8500,
      orderTrendPoints: [20, 40, 60, 80, 100, 120],
    },
    {
      outlets: 50,
      active: 35,
      offline: 10,
      disabled: 5,
      outletTrendPoints: [10, 20, 30, 40, 50, 60],
    },
    {
      totalRevenue: 25000,
      netProfit: 15000,
      revenueTrendPoints: [10000, 15000, 20000, 22000, 24000, 25000],
    },
    {
      totalApplications: 200,
      activeApplications: 150,
      pendingApplications: 30,
      rejectedApplications: 20,
      applicationTrendPoints: [50, 100, 150, 180, 190, 200],
    }
  ];


  // Chart options for the zigzag graph
  chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false // Hide legend for individual rows
      }
    },
    scales: {
      x: {
        display: false // Hide x-axis for compact design
      },
      y: {
        display: false // Hide y-axis for compact design
      }
    },
    elements: {
      line: {
        borderColor: '#4CAF50', // Line color (green)
        borderWidth: 2
      },
      point: {
        radius: 4, // Smaller points
        // hoverRadius: 2, // Prevent size increase on hover
        hitRadius: 2, // Define interaction radius
        backgroundColor: '#4CAF50' // Point color
      }
    }
  };

  switchPage(page: string): void {
    this.isOrdersPage = page === 'orders';
    this.isOutletsPage = page === 'outlets';
    this.isFinancePage = page === 'finance';
    this.isApplicationsPage = page === 'applications';
  }


  // Generate chart data for the trend
  getTrendData(trendPoints: number[]): any {
    return {
      labels: trendPoints.map((_, index) => index + 1), // Generate labels as [1, 2, 3, ...]
      datasets: [
        {
          data: trendPoints,
          fill: false
        }
      ]
    };
  }

  filterTable(filter: string): void {
    if (filter === 'today') {
      if (this.todayData && Array.isArray(this.todayData)) {
        this.filteredData = this.todayData;
      } else {
        console.error('todayData is not defined or is not an array.');
        this.filteredData = [];
      }
    } else if (filter === 'month') {
      if (this.monthData && Array.isArray(this.monthData)) {
        this.filteredData = this.monthData;
      } else {
        console.error('monthData is not defined or is not an array.');
        this.filteredData = [];
      }
    } else {
      console.error('Invalid filter value:', filter);
      this.filteredData = [];
    }
  }

  ngOnInit() {
    this.initChart();
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action']
        ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
        : undefined;
        console.warn(this.activeAction);
    });
  }

  initChart() {
    if (this.platformId) {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--p-text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');
      const surfaceBorder = documentStyle.getPropertyValue('--p-content-border-color');

      this.data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            label: 'My First dataset',
            backgroundColor: documentStyle.getPropertyValue('--p-cyan-500'),
            borderColor: documentStyle.getPropertyValue('--p-cyan-500'),
            data: [65, 59, 80, 81, 56, 55, 40, 72, 34, 25, 19, 62]
          },
          {
            label: 'My Second dataset',
            backgroundColor: documentStyle.getPropertyValue('--p-gray-500'),
            borderColor: documentStyle.getPropertyValue('--p-gray-500'),
            data: [28, 48, 40, 19, 86, 27, 90, 25, 76, 25, 58, 35]
          }
        ]
      };

      this.options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: textColor
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 500
              }
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false
            }
          },
          y: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false
            }
          }
        }
      };
      this.cd.markForCheck()
    }
  }

}
