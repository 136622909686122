<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Customers'"></app-title-bar>
    </div>
    <div class="sortSection d-flex justify-content-between">
        <div class="searchSection d-flex">
            <p-iconField iconPosition="left">
                <p-inputIcon styleClass="pi pi-search" />
                <input type="text" pInputText placeholder="Search" />
            </p-iconField>
        </div>
        <div class="d-flex gap-2">
            <div class="p-date d-flex">
                <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="true"
                    inputId="icondisplay" />
            </div>
        </div>
    </div>
    <div class="bodySection">
        <p-table [value]="customers" dataKey="customerId" [expandedRowKeys]="expandedRows"
            (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th>Customer ID</th>
                    <th>Customer Name</th>
                    <th>Mobile Number</th>
                    <!-- <th># Repeat Order</th> -->
                    <th>Address</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customers let-expanded="expanded">
                <tr>
                    <td>{{customers.id}}</td>
                    <td>{{customers.firstName +' '+ customers.lastName}}</td>
                    <td>{{customers.mobileNumber}}</td>
                    <!-- <td>{{customers.customerRepeatOrders}}</td> -->
                    <td>{{customers.address}}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-info-circle actionIconBtn" (click)="showDialog(customers)"></span>
                            <span class="pi pi-trash actionIconBtn"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-dialog [modal]="true" [(visible)]="visible" [style]="{ width: '30rem' , 'height':'30rem' }">
    <div class="d-flex flex-column gap-2">
        <div class="chipSection d-flex justify-content-end ">
            <div class="d-flex justify-content-center align-items-center">
                <span class="pi pi-times" (click)="DontshowDialog()"></span>
            </div>
        </div>
        <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
            (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
        <div class="orderHistoryCard" *ngIf="activeItem!.label == 'Order History'">
            <!-- <div class="newOrder d-flex flex-column gap-2">
                <div class="orderCard d-flex justify-content-between">
                    <div class="orderHistory d-flex flex-column gap-2">
                        <div class="chipPill">New</div>
                        <div class="orderId">
                            <span class="idName">Order Id : </span>
                            <span class="idNumber">236746</span>
                        </div>
                        <span class="timeSlot">Yesterday, 12:00 PM</span>
                        <div class="itemCount">
                            <span class="itemHistory">1x Chicken Sandwich + 1 item</span>
                        </div>
                    </div>
                    <div class="timeSection  d-flex justify-content-center align-items-center">
                        <span class="priceBlock">₹ 210 /-</span>
                    </div>
                </div>
            </div> -->
            <div class="previousOrders">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Previous Orders</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="orderCard d-flex justify-content-between" *ngFor="let order of customerOrders">
                                <div class="orderHistory d-flex flex-column gap-2">
                                    <div class="orderId">
                                        <span class="idName">Order Id : </span>
                                        <span class="idNumber">{{ order.orderId}}</span>
                                    </div>
                                    <span class="timeSlot">{{ order.orderDate | date: 'dd MMM, yyyy , HH:mm' }}</span>
                                    <div class="itemCount">
                                        <span class="itemHistory"> 
                                            {{ formatItems(order.items) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="timeSection d-flex justify-content-center align-items-center">
                                    <span class="priceBlock ">₹ {{ order.amount}} /-</span>

                                </div>
                            </div>
                            <!-- <div class="orderCard d-flex justify-content-between">
                                <div class="orderHistory d-flex flex-column gap-2">
                                    <div class="orderId">
                                        <span class="idName">Order Id : </span>
                                        <span class="idNumber">457297</span>
                                    </div>
                                    <span class="timeSlot">10/04/2024, 12:00 PM</span>
                                    <div class="itemCount">
                                        <span class="itemHistory">1x French Fries</span>
                                    </div>
                                </div>
                                <div class="timeSection d-flex justify-content-center align-items-center">
                                    <span class="priceBlock ">₹ 99 /-</span>

                                </div>
                            </div>
                            <div class="orderCard d-flex justify-content-between">
                                <div class="orderHistory d-flex flex-column gap-2">
                                    <div class="orderId">
                                        <span class="idName">Order Id : </span>
                                        <span class="idNumber">136746</span>
                                    </div>
                                    <span class="timeSlot">12/04/2024, 12:00 PM</span>
                                    <div class="itemCount">
                                        <span class="itemHistory">1x Fruit Custard</span>
                                    </div>
                                </div>
                                <div class="timeSection d-flex justify-content-center align-items-center">
                                    <span class="priceBlock">₹ 110 /-</span>
                                </div>
                            </div>
                            <div class="orderCard d-flex justify-content-between">
                                <div class="orderHistory d-flex flex-column gap-2">
                                    <div class="orderId">
                                        <span class="idName">Order Id : </span>
                                        <span class="idNumber">256746</span>
                                    </div>
                                    <span class="timeSlot">22/05/2024, 12:00 PM</span>
                                    <div class="itemCount">
                                        <span class="itemHistory">1x Chicken Roll + 2 items</span>
                                    </div>
                                </div>
                                <div class="timeSection d-flex justify-content-center align-items-center">
                                    <span class="priceBlock ">₹ 390 /-</span>
                                </div>
                            </div> -->
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="walletHistoryCard" *ngIf="activeItem!.label == 'Wallet History'"  >
            <div class="walletCard d-flex justify-content-between"  *ngFor="let transaction of customerWallet">
                <div class="statusCard d-flex gap-2">
                    <div class=" d-flex justify-content-center align-items-center">
                        <span class="pi pi-cloud-download circleIcon "></span>
                    </div>
                    <div class="matterinfo d-flex flex-column gap-2">
                        <span class="statusChip">{{ transaction.action }}</span>
                        <span class="timeSlot">{{ transaction.walletDate | date: 'dd MMM, yyyy , HH:mm' }}</span>
                    </div>
                </div>
                <div class="amountSection d-flex gap-2">
                    <span class="pi pi-plus d-flex justify-content-center align-items-center"></span>
                    <span class="rupeeBlock d-flex justify-content-center align-items-center"> ₹ {{ transaction.amount }} /-</span>
                </div>
            </div>
        </div>
    </div>
</p-dialog>