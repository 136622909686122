/**
 * @returns whether the provided parameter is a JavaScript Array or not.
 */
export function isArray(array) {
  return Array.isArray(array);
}
/**
 * @returns whether the provided parameter is a JavaScript String or not.
 */
export function isString(str) {
  return typeof str === 'string';
}
/**
 *
 * @returns whether the provided parameter is of type `object` but **not**
 *	`null`, an `array`, a `regexp`, nor a `date`.
 */
export function isObject(obj) {
  // The method can't do a type cast since there are type (like strings) which
  // are subclasses of any put not positvely matched by the function. Hence type
  // narrowing results in wrong results.
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj) && !(obj instanceof RegExp) && !(obj instanceof Date);
}
/**
 * In **contrast** to just checking `typeof` this will return `false` for `NaN`.
 * @returns whether the provided parameter is a JavaScript Number or not.
 */
export function isNumber(obj) {
  return typeof obj === 'number' && !isNaN(obj);
}
/**
 * @returns whether the provided parameter is a JavaScript Boolean or not.
 */
export function isBoolean(obj) {
  return obj === true || obj === false;
}
/**
 * @returns whether the provided parameter is undefined.
 */
export function isUndefined(obj) {
  return typeof obj === 'undefined';
}
/**
 * @returns whether the provided parameter is undefined or null.
 */
export function isUndefinedOrNull(obj) {
  return isUndefined(obj) || obj === null;
}
export function assertType(condition, type) {
  if (!condition) {
    throw new Error(type ? `Unexpected type, expected '${type}'` : 'Unexpected type');
  }
}
/**
 * Asserts that the argument passed in is neither undefined nor null.
 */
export function assertIsDefined(arg) {
  if (isUndefinedOrNull(arg)) {
    throw new Error('Assertion Failed: argument is undefined or null');
  }
  return arg;
}
/**
 * @returns whether the provided parameter is a JavaScript Function or not.
 */
export function isFunction(obj) {
  return typeof obj === 'function';
}
export function validateConstraints(args, constraints) {
  const len = Math.min(args.length, constraints.length);
  for (let i = 0; i < len; i++) {
    validateConstraint(args[i], constraints[i]);
  }
}
export function validateConstraint(arg, constraint) {
  if (isString(constraint)) {
    if (typeof arg !== constraint) {
      throw new Error(`argument does not match constraint: typeof ${constraint}`);
    }
  } else if (isFunction(constraint)) {
    try {
      if (arg instanceof constraint) {
        return;
      }
    } catch (_a) {
      // ignore
    }
    if (!isUndefinedOrNull(arg) && arg.constructor === constraint) {
      return;
    }
    if (constraint.length === 1 && constraint.call(undefined, arg) === true) {
      return;
    }
    throw new Error(`argument does not match one of these constraints: arg instanceof constraint, arg.constructor === constraint, nor constraint(arg) === true`);
  }
}
export function getAllPropertyNames(obj) {
  let res = [];
  let proto = Object.getPrototypeOf(obj);
  while (Object.prototype !== proto) {
    res = res.concat(Object.getOwnPropertyNames(proto));
    proto = Object.getPrototypeOf(proto);
  }
  return res;
}
export function getAllMethodNames(obj) {
  const methods = [];
  for (const prop of getAllPropertyNames(obj)) {
    if (typeof obj[prop] === 'function') {
      methods.push(prop);
    }
  }
  return methods;
}
export function createProxyObject(methodNames, invoke) {
  const createProxyMethod = method => {
    return function () {
      const args = Array.prototype.slice.call(arguments, 0);
      return invoke(method, args);
    };
  };
  let result = {};
  for (const methodName of methodNames) {
    result[methodName] = createProxyMethod(methodName);
  }
  return result;
}
/**
 * Converts null to undefined, passes all other values through.
 */
export function withNullAsUndefined(x) {
  return x === null ? undefined : x;
}