import { Registry } from '../../registry/common/platform.js';
import { createDecorator } from '../../instantiation/common/instantiation.js';
import { Extensions } from './configurationRegistry.js';
export const IConfigurationService = createDecorator('configurationService');
export function toValuesTree(properties, conflictReporter) {
  const root = Object.create(null);
  for (let key in properties) {
    addToValueTree(root, key, properties[key], conflictReporter);
  }
  return root;
}
export function addToValueTree(settingsTreeRoot, key, value, conflictReporter) {
  const segments = key.split('.');
  const last = segments.pop();
  let curr = settingsTreeRoot;
  for (let i = 0; i < segments.length; i++) {
    let s = segments[i];
    let obj = curr[s];
    switch (typeof obj) {
      case 'undefined':
        obj = curr[s] = Object.create(null);
        break;
      case 'object':
        break;
      default:
        conflictReporter(`Ignoring ${key} as ${segments.slice(0, i + 1).join('.')} is ${JSON.stringify(obj)}`);
        return;
    }
    curr = obj;
  }
  if (typeof curr === 'object' && curr !== null) {
    try {
      curr[last] = value; // workaround https://github.com/microsoft/vscode/issues/13606
    } catch (e) {
      conflictReporter(`Ignoring ${key} as ${segments.join('.')} is ${JSON.stringify(curr)}`);
    }
  } else {
    conflictReporter(`Ignoring ${key} as ${segments.join('.')} is ${JSON.stringify(curr)}`);
  }
}
export function removeFromValueTree(valueTree, key) {
  const segments = key.split('.');
  doRemoveFromValueTree(valueTree, segments);
}
function doRemoveFromValueTree(valueTree, segments) {
  const first = segments.shift();
  if (segments.length === 0) {
    // Reached last segment
    delete valueTree[first];
    return;
  }
  if (Object.keys(valueTree).indexOf(first) !== -1) {
    const value = valueTree[first];
    if (typeof value === 'object' && !Array.isArray(value)) {
      doRemoveFromValueTree(value, segments);
      if (Object.keys(value).length === 0) {
        delete valueTree[first];
      }
    }
  }
}
/**
 * A helper function to get the configuration value with a specific settings path (e.g. config.some.setting)
 */
export function getConfigurationValue(config, settingPath, defaultValue) {
  function accessSetting(config, path) {
    let current = config;
    for (const component of path) {
      if (typeof current !== 'object' || current === null) {
        return undefined;
      }
      current = current[component];
    }
    return current;
  }
  const path = settingPath.split('.');
  const result = accessSetting(config, path);
  return typeof result === 'undefined' ? defaultValue : result;
}
export function getConfigurationKeys() {
  const properties = Registry.as(Extensions.Configuration).getConfigurationProperties();
  return Object.keys(properties);
}
export function getDefaultValues() {
  const valueTreeRoot = Object.create(null);
  const properties = Registry.as(Extensions.Configuration).getConfigurationProperties();
  for (let key in properties) {
    let value = properties[key].default;
    addToValueTree(valueTreeRoot, key, value, message => console.error(`Conflict in default settings: ${message}`));
  }
  return valueTreeRoot;
}