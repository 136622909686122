import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ApplicationsService } from '../../services/applications.service';
import { CustomersService } from '../../services/customers.service';
import { ToastService } from '../../shared/toast.service';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss'
})
export class CustomersComponent {
  activeAction: string | null = null;
  details: any[] = [];
  idProofs: any[] = [];
  customers: any[] = [];
  vehicleProofs: any[] = [];
  expandedRows: any = {};
  value: string = '';
  selectedDate: Date | null = null;
  visible: boolean = false;
  activeItem: MenuItem | undefined;
  tabMenuItems: any[] = [];
  selectedUser: any;
  customerOrders: any[] = [];
  customerWallet: any[] = [];

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }

  showDialog(user: any) {
    this.visible = true;
    this.selectedUser= user;
    this.customersService.getOrderHistory(this.selectedUser.uid).subscribe((res) => {
      res.forEach(element => {
        this.customerOrders.push(element.data());
      });
    })

    this.customersService.getWalletHistory(this.selectedUser.uid, "Nov_2024").subscribe((res) => {
      res.forEach(element => {
        this.customerWallet.push(element.data());
      });
    })
  }
  DontshowDialog() {
    this.visible = false;
    this.selectedUser= null;
    this.customerOrders = [];
    this.customerWallet = [];
  }

  constructor(private activatedRoute: ActivatedRoute, private applicationService: ApplicationsService, private customersService: CustomersService, private toastService: ToastService) {
    this.tabMenuItems = [
      // { label: 'Log History', icon: 'fa-solid fa-bowl-food' },
      { label: 'Order History'},
      { label: 'Wallet History'},
    ];
    this.activeItem = this.tabMenuItems[0]

    this.customersService.getCustomers().subscribe((res) => {
      res.forEach(users => {
        this.customers.push(users.data());
      });
      console.log(this.customers);
    }, (err) => {
      this.toastService.showError(err)
    })
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action']
        ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
        : undefined;
    });
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.restaurantId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.restaurantId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');
  }

  formatItems(items: { itemName: string, quantity: number }[]): string {
    return items
      .map(item => `${item.quantity} x ${item.itemName}`)
      .join(', ');
  } 
}
