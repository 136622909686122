import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  userData: any;
  superAdminIds: any[] = ['1J9oC4lb2PMXG6oPCPNyS5fqhN63', 'zmDzhMy8HrheNFwvGrdLvLyls8w1', 'gRtFyvPwpFVD0nEfAhXKySAq5Ul1']
  staffCollection: AngularFirestoreCollection;


  constructor(private fireStore: AngularFirestore) { 
    this.staffCollection = fireStore.collection('staff');
  }

  setUserData(data: any) {
    this.userData = data;
  }

  getUserData() {
    return this.userData;
  }

  sendNotification(uid: any, obj: any) {
    return this.staffCollection.doc(uid).collection('notifications').add(obj);
  }


  checkAccess(featureName: string): boolean {
    const accessGroupList = JSON.parse(localStorage.getItem('accessGroupList') || '[]');
    if (!accessGroupList) {
      return false; // Or handle the case where accessGroupList is not available
    }

    return accessGroupList.some((item: any) => item.featureName === featureName); // Direct comparison
  }
}
