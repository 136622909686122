import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentData, QuerySnapshot } from '@angular/fire/compat/firestore';
import { catchError, lastValueFrom, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import { deleteObject, getStorage, listAll, ref } from 'firebase/storage'; // Import the getStorage function

@Injectable({
  providedIn: 'root'
})
export class DeliveryPartnerService {

  deliveryPartnersCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore, private http: HttpClient) {
    this.deliveryPartnersCollection = fireStore.collection('deliveryPartners');
  }

  getDeliveryPartners() {
    return this.deliveryPartnersCollection.get().pipe(
      map((querySnapshot: QuerySnapshot<DocumentData>) => {
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      })
    );
  }

  deleteDeliveryPartner(docId: any) {
    return this.deliveryPartnersCollection.doc(docId).delete();
  }

  async removeDeliveryPartner(uid: string): Promise<void> {
    try {
      // Call the Cloud Function via its HTTPS endpoint
      const result = await lastValueFrom(
        this.http.post<{ success?: boolean; error?: string }>(environment.cloudFunctionURL+'/deleteUser', { uid }).pipe(
          catchError((error) => {
            console.error('Error calling deleteUser endpoint:', error);
            throw new Error('Failed to delete user via Cloud Function.');
          })
        )
      );

      if (result?.success) {
        await this.deliveryPartnersCollection.doc(uid).delete();
        console.log(`User with UID ${uid} removed from Firestore.`);
        const storage = getStorage(); 
        const storageRef = ref(storage);
        const folderRef = ref(storage, `deliveryPartners/${uid}`); 
        await listAll(folderRef).then(async (res) => { // Use listAll()
          // Delete all files within the folder
          for (let itemRef of res.items) {
            await deleteObject(itemRef); // Use deleteObject()
          }
          // Delete the folder itself (empty folders are automatically deleted)
        });
        console.log(`Folder deliveryPartners/${uid} removed from Storage.`);
      } else {
        // Handle the error returned by the Cloud Function
        console.error(`Cloud Function error: ${result?.error}`);
        throw new Error(result?.error || 'Failed to delete user.');
      }
    } catch (error) {
      console.error('Error removing staff:', error);
      throw error; // Rethrow the error for further handling
    }
  }
}
