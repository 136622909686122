import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'; 
import * as monaco from 'monaco-editor';
import { TemplatesService } from '../../services/templates.service';
import { ToastService } from '../../shared/toast.service';
import { AuthService } from '../../services/auth.service';
import { GlobalDataService } from '../../services/global-data.service';
import firebase from 'firebase/compat/app';
import * as lottie from 'lottie-web';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrl: './templates.component.scss'
})

export class TemplatesComponent {

  showSpinner: boolean = false;
  showAddTemplateModal: boolean = false;
  isUpdateTemplate: boolean = false;
  addTemplateForm: FormGroup;
  @ViewChild('editorContainer') editorContainer: ElementRef | undefined;
  editor: monaco.editor.IStandaloneCodeEditor | undefined;
  previewHtml: SafeHtml = '';
  private editorSubscription: monaco.IDisposable | undefined;
  templates: any[] = [];
  selectedTemplate: any;
  user: any;
  userData: any;
  @ViewChildren('animationContainer') animationContainers!: QueryList<ElementRef>;
  showAnimationContainer = false;
  isDataLoading: boolean = false;
  animationInstance: any;
  animationInstances: any[] = [];

  // Map of animation container IDs to their respective animation file paths
  animationFilePaths: { [key: string]: string } = {
    trash: 'assets/animIcons/trashIcon.json',
    edit: 'assets/animIcons/penIcon.json',
    // pause: 'assets/animIcons/pauseIcon.json',
    // add: 'assets/animIcons/plusIcon.json'
  };

  constructor(private fb: FormBuilder, private sanitizer: DomSanitizer, private templateService: TemplatesService, private toastService: ToastService,  private globalDataService: GlobalDataService, private authService: AuthService, private http: HttpClient) {
      this.addTemplateForm = this.fb.group({
        templateName: ['', [Validators.required]],
        templateDescription: ['', [Validators.required]],
        templateValue: [''],
      });
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          this.user = user;
          this.userData = this.globalDataService.getUserData();
          if (!this.userData) {
            this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
              this.userData = res.data();
              this.globalDataService.setUserData(this.userData);
            })
          }
        } else {
          console.warn("User not logged in");
          // User not logged in or has just logged out.
        }
      });
      this.getTemplates();
  }

  ngAfterViewInit(): void {
    if (this.editorContainer) { 
      this.editor = monaco.editor.create(this.editorContainer.nativeElement, {
        value: `<div>
          <h1>Hello, World!</h1>
        </div>`,
        language: 'html', 
        theme: 'vs-dark', 
        automaticLayout: true, 
        tabSize: 4,
        minimap: {
          enabled: false,
          scale: 2,
          side: 'right'
        },
      });
      this.editorSubscription = this.editor.onDidChangeModelContent(() => {
        this.previewCode(); 
      });
    }
    this.showAnimationContainer = true;
    this.isDataLoading = true;
    this.loadIcons();
  }

  loadIcons() {
    if(this.animationInstances.length > 0) {
      this.animationInstances.forEach((element: any) => {
        element.destroy();
      });
    }
    var timer = setInterval(() => {
      if(this.animationContainers.length > 0) {
        clearInterval(timer);
        this.animationContainers.forEach((animationContainer: ElementRef, index: number) => {
          const locationId = animationContainer.nativeElement.dataset.locationId;
          const animationType = animationContainer.nativeElement.dataset.animationType;
          const animationFilePath = this.animationFilePaths[animationType];
  
          this.http.get(animationFilePath).subscribe((animationData: any) => {
            const animationInstance = (lottie as any).loadAnimation({
              container: animationContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: false,
              animationData: animationData
            });
  
            animationInstance.setSpeed(0.5);
  
            const svgElement = animationContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              console.log("SVG element found:", svgElement);
              svgElement.style.fill = '#4b5563';
            } else {
              console.error("SVG element not found for container:", animationContainer.nativeElement);
            }
  
            this.animationInstances[index] = animationInstance;
  
            animationContainer.nativeElement.addEventListener('mouseenter', () => {
              this.animationInstances[index].setDirection(1);
              this.animationInstances[index].play();
            });
  
            animationContainer.nativeElement.addEventListener('mouseleave', () => {
              this.animationInstances[index].setDirection(-1);
              this.animationInstances[index].play();
            });
          });
        });
        this.isDataLoading = false;
      }
    }, );
  }

  getTemplates() {
    this.templates = [];
    this.templateService.getTemplates().subscribe((res: any) => {
      res.forEach((template: any) => {
        const templateData = template.data();
        const templateId = template.id; // Get the document ID
  
        this.templates.push({
          ...templateData, // Spread the existing data
          id: templateId    // Add the ID
        });
      });
    });
  }

  ngOnDestroy() {
    if (this.editorSubscription) {
      this.editorSubscription.dispose();
    }
  }

  toggleAddTemplateModal(template?: any, isClose?: boolean) {
    if(isClose) {
      this.showAddTemplateModal = false;
      this.isUpdateTemplate = false;
      this.addTemplateForm.reset();
      this.editor?.setValue('');
      this.selectedTemplate = null;
    } else {
      this.showAddTemplateModal = true;
      if(template) {
        this.isUpdateTemplate = true;
        this.selectedTemplate = template
        this.addTemplateForm.patchValue({
          templateName: template.templateName,
          templateDescription: template.templateDescription,
        });
        this.editor?.setValue(template.templateValue)
      } else {
        this.isUpdateTemplate = false;
      }
      this.previewCode(); 
    }
  }

  saveCode() {
    this.showSpinner = true;
    var obj = {
      templateName: this.addTemplateForm.value.templateName,
      templateDescription: this.addTemplateForm.value.templateDescription,
      templateValue: this.editor?.getValue(),
      createdAt: new Date().getTime(),
      createdById: this.user.uid,
      createdBy: this.userData.firstName + ' ' + this.userData.lastName,
      lastUpdatedAt: new Date().getTime(),
      lastUpdatedById: this.user.uid,
      lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName
    }
    this.templateService.addTemplate(obj).then((res: any) => {
      this.toastService.showSuccess("Success", `${this.addTemplateForm.value.templateName} template has been successfully added`);
      this.toggleAddTemplateModal(null, true);
      this.addTemplateForm.reset();
      this.getTemplates();
      this.loadIcons();
      this.showSpinner = false;
    }, err => {
      this.showSpinner = false;
      this.toastService.showError("Error", `Error while adding ${this.addTemplateForm.value.templateName} template`)
    })
  }

  updateCode() {
    this.showSpinner = true;
    var obj = {
      templateName: this.addTemplateForm.value.templateName,
      templateDescription: this.addTemplateForm.value.templateDescription,
      templateValue: this.editor?.getValue(),
      lastUpdatedAt: new Date().getTime(),
      lastUpdatedById: this.user.uid,
      lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName
    }
    this.templateService.updateTemplate(this.selectedTemplate.id, obj).then((res: any) => {
      this.toastService.showSuccess("Success", `${this.addTemplateForm.value.templateName} template has been successfully updated`);
      this.toggleAddTemplateModal(null, true);
      this.addTemplateForm.reset();
      this.getTemplates();
      this.loadIcons();
      this.showSpinner = false;
    }, err => {
      this.showSpinner = false;
      this.toastService.showError("Error", `Error while updating ${this.addTemplateForm.value.templateName} template`)
    })
  }

  previewCode() {
    if (this.editor) {
      const code = this.editor.getValue();
      this.previewHtml = this.sanitizer.bypassSecurityTrustHtml(code);
    }
  }

  deleteTemplate(template: any) {
    this.showSpinner = true;
    this.templateService.deleteTemplate(template.id).then((res: any) => {
      this.toastService.showSuccess("Success", `${this.addTemplateForm.value.templateName} has been successfully deleted`);
      this.getTemplates();
      this.loadIcons();
      this.showSpinner = false;
    }, err => {
      this.showSpinner = false;
      this.toastService.showError("Error", `Error while deleting ${template.templateName} template`)
    })
  }

  checkEditorValue(): boolean {
    if (this.editor?.getValue().trim().length === 0) { // Check for empty or whitespace-only content
      return true; // Disable the button if editor is empty
    } else {
      return false; // Enable the button if there's content
    }
  }
}
