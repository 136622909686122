import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationsService } from '../../services/applications.service';
import { MenuItem } from 'primeng/api';
import { DeliveryPartnerService } from '../../services/delivery-partner.service';
import { ToastService } from '../../shared/toast.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-delivery-partner',
  templateUrl: './delivery-partner.component.html',
  styleUrl: './delivery-partner.component.scss'
})
export class DeliveryPartnerComponent {

  activeAction: string | null = null;
  details: any[] = [];
  idProofs: any[] = [];
  partners: any[] =[];
  vehicleProofs: any[] = [];
  expandedRows: any = {};
  value: string = '';
  selectedDate: Date | null = null;
  visible: boolean = false;
  rejectVisible: boolean = false;
  EditVisible: boolean = false; 
  activeTable: 'basic' | 'idProofs' | 'vehicleProofs' | 'bank' |null = null;
  showSpinner: boolean = false;
  selectedPartner: any;
  showDeleteConfirmationModal: boolean = false;
  basicDetailsForm: FormGroup;
  aadhaarDataForm: FormGroup;
  PANDataForm: FormGroup;
  bankDataForm: FormGroup;
  activeItem: MenuItem | undefined;
  tabMenuItems: any[] = [];
  rcDetailsForm: FormGroup;
  licenseDetailsForm: FormGroup;

  onActiveItemChange(event: any) {
    this.activeItem = event;
  }

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private deliveryPartnerService: DeliveryPartnerService, private toastService: ToastService) {
    this.getDeliveryPartners();
    this.basicDetailsForm = this.fb.group({
      ownerId: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      emailId: [''],
      mobileNumber: ['', [Validators.required]],
      address: ['', [Validators.required]],
      gender: ['', [Validators.required]]
    });

    this.aadhaarDataForm = this.fb.group({
      aadhaarNumber: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      gender: ['', [Validators.required]]
    });

    this.PANDataForm = this.fb.group({
      panNumber: ['', [Validators.required]],
      fullName: ['', [Validators.required]],
      category: ['', [Validators.required]]
    });

    this.bankDataForm = this.fb.group({
      bankAccountNumber: ['', [Validators.required]],
      IFSCCode: ['', [Validators.required]]
    });

    this.rcDetailsForm = this.fb.group({
      ownerName: [''],
      rcNumber: ['', [Validators.required]],
      rcStatus: ['', [Validators.required]],
      insuranceCompany: [''],
      insurancePolicyNumber: ['', [Validators.required]],
      insuranceUpto: ['', [Validators.required]],
      manufacturer: ['', [Validators.required]],
      model: [''],
      chasiNumber: ['', [Validators.required]],
      engineNumber: ['', [Validators.required]],
      permanentAddress: [''],
      presentAddress: ['', [Validators.required]],
      registeredAt: ['', [Validators.required]],
      vehicleCategory: ['', [Validators.required]]
    });

    this.licenseDetailsForm = this.fb.group({
      fullName: [''],
      DOB: ['', [Validators.required]],
      currentStatus: ['', [Validators.required]],
      DOI: [''],
      DOE: ['', [Validators.required]],
      licenseNumber: ['', [Validators.required]],
      olaCode: ['', [Validators.required]],
      olaName: [''],
      permanentAddress: ['', [Validators.required]],
      vehicleClass: ['', [Validators.required]]
    });
  }

  getDeliveryPartners() {
    this.partners = [];
    this.deliveryPartnerService.getDeliveryPartners().subscribe((res) => {
      res.forEach(deliveryPartner => {
        this.partners.push(deliveryPartner);
      });
    }, (err) => {
      this.toastService.showError(err)
    })

    this.tabMenuItems = [
      // { label: 'Log History', icon: 'fa-solid fa-bowl-food' },
      { label: 'Basic Details'},
      { label: 'Id Proofs'},
      { label: 'Vehical Proofs'},
      { label: 'Bank Details'},
    ];
    this.activeItem = this.tabMenuItems[0]

  }

  toggleVehicleProofs() {
    this.activeTable = this.activeTable === 'vehicleProofs' ? null : 'vehicleProofs'; 
  }

  toggleBasicDetails() {
    this.activeTable = this.activeTable === 'basic' ? null : 'basic';
  }

  toggleIdProofs() {
    this.activeTable = this.activeTable === 'idProofs' ? null : 'idProofs'; 
  }

  toggleBankDetails() {
    this.activeTable = this.activeTable === 'bank' ? null : 'bank'; 
  }

    showDialog(partner: any) {
        this.visible = true;
        this.selectedPartner = partner;
        this.toggleBasicDetails();
        this.activeTable === 'basic';
    }
    
    DontshowDialog() {
      this.visible = false;
  }

  showRejectDialog() {
    this.rejectVisible = true;
  }

DontshowRejectDialog() {
  this.rejectVisible = false;
}
  showEditDialog(partners: any) {
    this.selectedPartner = partners; 
    this.EditVisible = true;
    this.basicDetailsForm.patchValue({
      firstName: partners.basicDetails?.firstName,
      lastName: partners.basicDetails?.lastName ,
      mobileNumber: partners.basicDetails?.mobileNumber,
      emailId: partners.basicDetails?.email,
      address:partners.basicDetails?.address,
      gender:partners.basicDetails?.gender,
    });
    this.basicDetailsForm.controls['ownerId'].disable();   
    this.basicDetailsForm.controls['emailId'].disable();
    this.basicDetailsForm.controls['gender'].disable();
    
    this.aadhaarDataForm.patchValue({
      aadhaarNumber:partners.aadhaarData?.aadhaar_number,
      fullName:  partners.aadhaarData?.full_name ,
      dateOfBirth : partners.aadhaarData?.gender ,
      gender: partners.aadhaarData?.gender ,
      
    });
    this.aadhaarDataForm.controls['aadhaarNumber'].disable();   
    this.aadhaarDataForm.controls['fullName'].disable();
    this.aadhaarDataForm.controls['dateOfBirth'].disable();
    this.aadhaarDataForm.controls['gender'].disable();

    this.PANDataForm.patchValue({
      panNumber : partners.panData?.pan_number,
      fullName: partners.panData?.full_name,
      category: partners.panData?.category
      
    });
    this.PANDataForm.controls['panNumber'].disable();   
    this.PANDataForm.controls['fullName'].disable();
    this.PANDataForm.controls['category'].disable();

    this.bankDataForm.patchValue({
      bankAccountNumber : partners.primaryBankAccData.bankAccNumber,
      IFSCCode: partners.primaryBankAccData.bankIFSC
    });
    this.bankDataForm.controls['bankAccountNumber'].disable();   
    this.bankDataForm.controls['IFSCCode'].disable();

    this.rcDetailsForm.patchValue({
      ownerName : partners.vehicleData?.owner_name,
      rcNumber : partners.vehicleData?.rc_number,
      rcStatus : partners.vehicleData?.rc_status,
      insuranceCompany : partners.vehicleData?.insurance_company,
      insurancePolicyNumber : partners.vehicleData?.insurance_policy_number,
      insuranceUpto : partners.vehicleData?.insurance_upto,
      manufacturer : partners.vehicleData?.maker_description,
      model  :partners.vehicleData?.maker_model,
      chasiNumber : partners.vehicleData?.vehicle_chasi_number,
      engineNumber : partners.vehicleData?.vehicle_engine_number,
      permanentAddress : partners.vehicleData?.permanent_address,
      presentAddress : partners.vehicleData?.present_address,
      registeredAt : partners.vehicleData?.registered_at,
      vehicleCategory  : partners.vehicleData?.vehicle_category
      
    });  
    this.rcDetailsForm.controls['ownerName'].disable();
    this.rcDetailsForm.controls['rcNumber'].disable();
    this.rcDetailsForm.controls['rcStatus'].disable();
    this.rcDetailsForm.controls['insuranceCompany'].disable();   
    this.rcDetailsForm.controls['insurancePolicyNumber'].disable();
    this.rcDetailsForm.controls['insuranceUpto'].disable();
    this.rcDetailsForm.controls['manufacturer'].disable(); 
    this.rcDetailsForm.controls['model'].disable();
    this.rcDetailsForm.controls['chasiNumber'].disable();   
    this.rcDetailsForm.controls['engineNumber'].disable();
    this.rcDetailsForm.controls['permanentAddress'].disable();
    this.rcDetailsForm.controls['presentAddress'].disable();
    this.rcDetailsForm.controls['registeredAt'].disable();   
    this.rcDetailsForm.controls['vehicleCategory'].disable();

    this.licenseDetailsForm.patchValue({
      fullName : partners.drivingLicenseData?.name,
      DOB : partners.drivingLicenseData?.dob,
      currentStatus : partners.drivingLicenseData?.current_status,
      DOI : partners.drivingLicenseData?.doi,
      DOE : partners.drivingLicenseData?.doe,
      licenseNumber : partners.drivingLicenseData?.license_number,
      olaCode : partners.drivingLicenseData?.ola_code,
      olaName  : partners.drivingLicenseData?.ola_name,
      permanentAddressr : partners.drivingLicenseData?.permanent_address ?
      partners.drivingLicenseData?.permanent_address : '-',
      vehicleClass : partners.drivingLicenseData?.vehicle_classes.join(', ') ,
      
    });
    this.licenseDetailsForm.controls['fullName'].disable();   
    this.licenseDetailsForm.controls['DOB'].disable();
    this.licenseDetailsForm.controls['currentStatus'].disable();
    this.licenseDetailsForm.controls['DOI'].disable();
    this.licenseDetailsForm.controls['DOE'].disable();   
    this.licenseDetailsForm.controls['licenseNumber'].disable();
    this.licenseDetailsForm.controls['olaCode'].disable();
    this.licenseDetailsForm.controls['olaName'].disable();
    this.licenseDetailsForm.controls['permanentAddress'].disable();   
    this.licenseDetailsForm.controls['vehicleClass'].disable();

    
  }
  DontshowEditDialog() {
    this.selectedPartner = null;
    this.EditVisible = false;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.activeAction = params['action'] 
      ? params['action'].charAt(0).toUpperCase() + params['action'].slice(1)
      : undefined;
    });
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.restaurantId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.restaurantId];
  }

  openInMaps(latitude: number, longitude: number) {
    const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(url, '_blank');   
  }

  showDeleteConfirmation(deliveryPartner: any) {
    this.showDeleteConfirmationModal =! this.showDeleteConfirmationModal
    if(this.showDeleteConfirmationModal) {
      this.selectedPartner = deliveryPartner
    } else {
      this.selectedPartner = null
    }
  }

  deleteDeliveryPartner(partnerId: any) {
    this.showSpinner = true;
    this.deliveryPartnerService.deleteDeliveryPartner(partnerId).then((res: any) => {
      this.deliveryPartnerService.removeDeliveryPartner(partnerId).then((result) => {
        this.getDeliveryPartners();
        this.showSpinner = false;
        this.showDeleteConfirmationModal = false;
        this.toastService.showSuccess("Delete Success", `Successfully removed ${this.selectedPartner.deliveryPartnerId}`)
      }, err => {
        this.showSpinner = false;
      })
    }, err => {
      this.showSpinner = false;
    })
  }

}
