/**
 * Vertical Lane in the overview ruler of the editor.
 */
export var OverviewRulerLane;
(function (OverviewRulerLane) {
  OverviewRulerLane[OverviewRulerLane["Left"] = 1] = "Left";
  OverviewRulerLane[OverviewRulerLane["Center"] = 2] = "Center";
  OverviewRulerLane[OverviewRulerLane["Right"] = 4] = "Right";
  OverviewRulerLane[OverviewRulerLane["Full"] = 7] = "Full";
})(OverviewRulerLane || (OverviewRulerLane = {}));
/**
 * Position in the minimap to render the decoration.
 */
export var MinimapPosition;
(function (MinimapPosition) {
  MinimapPosition[MinimapPosition["Inline"] = 1] = "Inline";
  MinimapPosition[MinimapPosition["Gutter"] = 2] = "Gutter";
})(MinimapPosition || (MinimapPosition = {}));
export class TextModelResolvedOptions {
  /**
   * @internal
   */
  constructor(src) {
    this.tabSize = Math.max(1, src.tabSize | 0);
    this.indentSize = src.tabSize | 0;
    this.insertSpaces = Boolean(src.insertSpaces);
    this.defaultEOL = src.defaultEOL | 0;
    this.trimAutoWhitespace = Boolean(src.trimAutoWhitespace);
  }
  /**
   * @internal
   */
  equals(other) {
    return this.tabSize === other.tabSize && this.indentSize === other.indentSize && this.insertSpaces === other.insertSpaces && this.defaultEOL === other.defaultEOL && this.trimAutoWhitespace === other.trimAutoWhitespace;
  }
  /**
   * @internal
   */
  createChangeEvent(newOpts) {
    return {
      tabSize: this.tabSize !== newOpts.tabSize,
      indentSize: this.indentSize !== newOpts.indentSize,
      insertSpaces: this.insertSpaces !== newOpts.insertSpaces,
      trimAutoWhitespace: this.trimAutoWhitespace !== newOpts.trimAutoWhitespace
    };
  }
}
export class FindMatch {
  /**
   * @internal
   */
  constructor(range, matches) {
    this.range = range;
    this.matches = matches;
  }
}
/**
 * @internal
 */
export class ValidAnnotatedEditOperation {
  constructor(identifier, range, text, forceMoveMarkers, isAutoWhitespaceEdit, _isTracked) {
    this.identifier = identifier;
    this.range = range;
    this.text = text;
    this.forceMoveMarkers = forceMoveMarkers;
    this.isAutoWhitespaceEdit = isAutoWhitespaceEdit;
    this._isTracked = _isTracked;
  }
}
/**
 * @internal
 */
export class ApplyEditsResult {
  constructor(reverseEdits, changes, trimAutoWhitespaceLineNumbers) {
    this.reverseEdits = reverseEdits;
    this.changes = changes;
    this.trimAutoWhitespaceLineNumbers = trimAutoWhitespaceLineNumbers;
  }
}