<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer">
    <div><app-title-bar [activeNav]="'Templates'"> </app-title-bar></div>
    <div class="bodySection">
        <div class="mb-3 d-flex justify-content-end">
            <span>
                <p-button label="Add Template" icon="pi pi-plus" (onClick)="toggleAddTemplateModal()" />
            </span>
        </div>
        <p-table [value]="templates" dataKey="id">
            <ng-template pTemplate="header">
                <tr>
                    <th>Template Name</th>
                    <th>Template Description</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-template let-expanded="expanded">
                <tr>
                    <td>{{ template.templateName }}</td>
                    <td>{{ template.templateDescription }}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span *ngIf="showAnimationContainer" #animationContainer [attr.data-animation-type]="'edit'"
                                [attr.data-location-id]="template.templateName" class="d-flex animEditIcon" (click)="toggleAddTemplateModal(template, false)"></span>
                             <!-- <span class="pi pi-pen-to-square actionIconBtn" (click)="toggleAddTemplateModal(template, false)"></span> -->
                            <!-- <span class="pi pi-pen-to-square actionIconBtn"></span> -->
                            <span *ngIf="showAnimationContainer" #animationContainer
                            [attr.data-animation-type]="'trash'" [attr.data-location-id]="template.templateName"
                            class="d-flex animTrashIcon" (click)="deleteTemplate(template)">
                        </span>
                           <!-- <span class="pi pi-trash actionIconBtn" (click)="deleteTemplate(template)"></span>-->
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">
                        <div class="d-flex justify-content-center">
                            No templates found
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [header]="isUpdateTemplate ? 'Update Template' : 'Add Template'" class="signUpModal" [modal]="true"
    (onHide)="toggleAddTemplateModal(null, true)" [(visible)]="showAddTemplateModal" [draggable]="false"
    [closable]="true">
    <div class="d-flex">
        <div class="templateDetails">
            <form [formGroup]="addTemplateForm">
                <div class="d-flex flex-column gap-3 mb-4">
                    <div class="d-flex flex-column gap-2">
                        <label for="templateName">Template Name</label>
                        <input pInputText id="templateName" formControlName="templateName" />
                    </div>
                    <div class="d-flex flex-column gap-2">
                        <label for="templateDescription">Template Description</label>
                        <input pInputText id="templateDescription" formControlName="templateDescription" />
                    </div>
                    <div class="d-flex flex-column gap-2">
                        <label for="templateValue">Template Value</label>
                        <!-- <textarea #codeEditor></textarea> -->
                        <div #editorContainer style="height: 42vh;"></div>
                    </div>
                </div>
            </form>
            <div class="d-flex justify-content-end gap-2">
                <!-- <p-button [label]="isUpdateLocation ? selectedLocation.status == 'rejected' ? 'Resubmit' : 'Update' : 'Add'"
                    [disabled]="addLocationForm.invalid"
                    (onClick)="registerNewLocation(selectedLocation?.status == 'rejected' ? true : false)" /> -->
                <p-button *ngIf="!isUpdateTemplate" [disabled]="addTemplateForm.invalid || checkEditorValue()" [label]="'Add'" (onClick)="saveCode()"/>
                <p-button *ngIf="isUpdateTemplate" [disabled]="addTemplateForm.invalid || checkEditorValue()" [label]="'Update'" (onClick)="updateCode()"/>
            </div>
        </div>
        <div class="lineSeparator"></div>
        <div class="templatePreview" [innerHTML]="previewHtml">
        </div>
    </div>
</p-dialog>