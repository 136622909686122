<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Delivery Partner'"></app-title-bar>
    </div>
    <!-- <div class="sortSection d-flex justify-content-between">
        <div class="searchSection d-flex">
            <p-iconField iconPosition="left">
                <p-inputIcon styleClass="pi pi-search" />
                <input type="text" pInputText placeholder="Search" />
            </p-iconField>
        </div>
        <div class="d-flex gap-2">
            <div class="p-date d-flex">
                <p-calendar [(ngModel)]="selectedDate" [iconDisplay]="'input'" [showIcon]="true"
                    inputId="icondisplay" />
            </div>
        </div>
    </div> -->
    <div class="bodySection">
        <p-table [value]="partners" dataKey="partnerId" [expandedRowKeys]="expandedRows"
            (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th>Delivery Partner ID</th>
                    <th>Delivery Partner Email</th>
                    <th>Delivery Partner Name</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-deliveryPartner let-expanded="expanded">
                <tr>
                    <td>{{deliveryPartner?.deliveryPartnerId ? deliveryPartner?.deliveryPartnerId : '-'}}</td>
                    <td>{{deliveryPartner?.email ? deliveryPartner?.email : '-'}}</td>
                    <td>{{deliveryPartner.basicDetails?.firstName ? deliveryPartner.basicDetails?.firstName +' '+ deliveryPartner.basicDetails?.lastName : '-'}}</td>
                    <td class="d-flex gap-3 align-items-center">
                        <span class="statusPill" [ngClass]="{
                        'activePill':  deliveryPartner?.status  === 'active',
                        'pendingPill':  deliveryPartner?.status  === 'pending',
                        'rejectedPill':  deliveryPartner?.status  === 'rejected',
                        'disabledPill':  deliveryPartner?.status  === 'disabled'
                        }">{{ deliveryPartner.status ? deliveryPartner.status : '-' }}</span>
                    <td>
                        <div class="d-flex gap-2">
                            <span class="pi pi-info-circle actionIconBtn" (click)="showDialog(deliveryPartner)"></span>
                            <span class="pi pi-pen-to-square actionIconBtn"
                                (click)="showEditDialog(deliveryPartner)"></span>
                            <span class="pi pi-check-square actionIconBtn"></span>
                            <span class="pi pi-times-circle actionIconBtn"
                            (click)="showRejectDialog()"></span>
                            <span class="pi pi-trash actionIconBtn"
                                (click)="showDeleteConfirmation(deliveryPartner)"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-dialog [modal]="true" [(visible)]="visible" [style]="{ width: '30rem' , 'height':'30rem' }">
    <div class="d-flex flex-column gap-2">
        <div class="chipSection d-flex justify-content-between ">
            <div class="d-flex gap-3">
                <span class="basicDetailsChip" (click)="toggleBasicDetails()">Basic Details</span>
                <span class="IdProofChip" (click)="toggleIdProofs()">Id Proofs</span>
                <span class="VehicalChip" (click)="toggleVehicleProofs()">Vehicle Proofs</span>
                <span class="BankChip" (click)="toggleBankDetails()">Bank Details</span>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <span class="pi pi-times" (click)="DontshowDialog()"></span>
            </div>
        </div>
        <div class="detailsInfo">
            <p-table [value]="[selectedPartner]" *ngIf="activeTable === 'basic'" [expandedRowKeys]="expandedRows"
                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Label</th>
                        <th>Value</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                    <tr>
                        <td>Profile</td>
                        <td>
                            <div style="width: 150px; height: 150px; border-radius: 10px;">
                                <img [src]="partner.basicDetails?.profileImageUrl" alt="Profile Image"
                                    style="width: 100%; height: 100%; object-fit: contain;">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>First Name</td>
                        <td>{{ partner.basicDetails?.firstName }}</td>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <td>{{ partner.basicDetails?.lastName }}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ partner.basicDetails?.email }}</td>
                    </tr>
                    <tr>
                        <td>Mobile Number</td>
                        <td>{{ partner.basicDetails?.mobileNumber }}</td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td>{{ partner.basicDetails?.address }}</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>{{ partner.basicDetails?.gender }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="detailsInfo">
            <p-table [value]="[selectedPartner]" *ngIf="activeTable === 'bank'" [expandedRowKeys]="expandedRows"
                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                    <tr>
                        <td>Bank Account Number</td>
                        <td>{{ partner.primaryBankAccData.bankAccNumber }}</td>
                    </tr>
                    <tr>
                        <td>IFSC Code</td>
                        <td>{{ partner.primaryBankAccData.bankIFSC }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="accordionField" *ngIf="activeTable === 'idProofs'">
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Aadhaar Data</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="detailsInfo">
                            <p-table [value]="[selectedPartner]" [expandedRowKeys]="expandedRows"
                                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Label</th>
                                        <th>Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                                    <tr>
                                        <td>Profile</td>
                                        <td>
                                            <div style="width: 200px; height: 200px; border-radius: 10px;">
                                                <img [src]="'data:image/jpeg;base64,' + partner.aadhaarData?.profile_image"
                                                    alt="Profile Image"
                                                    style="width: 100%; height: 100%; object-fit: contain;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Aadhaar Number</td>
                                        <td>{{ partner.aadhaarData?.aadhaar_number }}</td>
                                    </tr>
                                    <tr>
                                        <td>Full Name</td>
                                        <td>{{ partner.aadhaarData?.full_name }}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Birth</td>
                                        <td>{{ partner.aadhaarData?.dob }}</td>
                                    </tr>
                                    <tr>
                                        <td>Gender</td>
                                        <td>{{ partner.aadhaarData?.gender }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">PAN Data</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="detailsInfo">
                            <p-table [value]="[selectedPartner]" [expandedRowKeys]="expandedRows"
                                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Label</th>
                                        <th>Value</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                                    <tr>
                                        <td>PAN Number</td>
                                        <td>{{ partner.panData?.pan_number }}</td>
                                    </tr>
                                    <tr>
                                        <td>Full Name</td>
                                        <td>{{ partner.panData?.full_name }}</td>
                                    </tr>
                                    <tr>
                                        <td>Category</td>
                                        <td>{{ partner.panData?.category }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
        <div class="accordionField" *ngIf="activeTable === 'vehicleProofs'">
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Driving License Details</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="detailsInfo">
                            <p-table [value]="[selectedPartner]" [expandedRowKeys]="expandedRows"
                                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                                    <tr>
                                        <td>Profile</td>
                                        <td>
                                            <div style="width: 200px; height: 200px; border-radius: 10px;">
                                                <img [src]="'data:image/jpeg;base64,' + partner.drivingLicenseData?.profile_image"
                                                    alt="Profile Image"
                                                    style="width: 100%; height: 100%; object-fit: contain;">
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Full Name</td>
                                        <td>{{partner.drivingLicenseData?.name}}</td>
                                    </tr>
                                    <tr>
                                        <td>DOB</td>
                                        <td>{{partner.drivingLicenseData?.dob}}</td>
                                    </tr>
                                    <tr>
                                        <td>Current Status</td>
                                        <td>{{partner.drivingLicenseData?.current_status}}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Issue</td>
                                        <td>{{partner.drivingLicenseData?.doi}}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Expiry</td>
                                        <td>{{partner.drivingLicenseData?.doe}}</td>
                                    </tr>
                                    <tr>
                                        <td>License Number</td>
                                        <td>{{partner.drivingLicenseData?.license_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>OLA Code</td>
                                        <td>{{partner.drivingLicenseData?.ola_code}}</td>
                                    </tr>
                                    <tr>
                                        <td>OLA Name</td>
                                        <td>{{partner.drivingLicenseData?.ola_name}}</td>
                                    </tr>
                                    <tr>
                                        <td>Permanent Address</td>
                                        <td>{{partner.drivingLicenseData?.permanent_address ?
                                            partner.drivingLicenseData?.permanent_address : '-'}}</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle Classes</td>
                                        <td>{{ partner.drivingLicenseData?.vehicle_classes.join(', ') }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">RC Details</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="detailsInfo">
                            <p-table [value]="[selectedPartner]" [expandedRowKeys]="expandedRows"
                                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-partner let-expanded="expanded">
                                    <tr>
                                        <td>Owner Name</td>
                                        <td>{{partner.vehicleData?.owner_name}}</td>
                                    </tr>
                                    <tr>
                                        <td>RC Number</td>
                                        <td>{{partner.vehicleData?.rc_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>RC Status</td>
                                        <td>{{partner.vehicleData?.rc_status}}</td>
                                    </tr>
                                    <tr>
                                        <td>Insurance Company</td>
                                        <td>{{partner.vehicleData?.insurance_company}}</td>
                                    </tr>
                                    <tr>
                                        <td>Insurance Policy Number</td>
                                        <td>{{partner.vehicleData?.insurance_policy_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>Insurance Upto</td>
                                        <td>{{partner.vehicleData?.insurance_upto}}</td>
                                    </tr>
                                    <tr>
                                        <td>Manufacturer</td>
                                        <td>{{partner.vehicleData?.maker_description}}</td>
                                    </tr>
                                    <tr>
                                        <td>Model</td>
                                        <td>{{partner.vehicleData?.maker_model}}</td>
                                    </tr>
                                    <tr>
                                        <td>Chasi Number</td>
                                        <td>{{partner.vehicleData?.vehicle_chasi_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>Engine Number</td>
                                        <td>{{partner.vehicleData?.vehicle_engine_number}}</td>
                                    </tr>
                                    <tr>
                                        <td>Permanent Address</td>
                                        <td>{{partner.vehicleData?.permanent_address}}</td>
                                    </tr>
                                    <tr>
                                        <td>Present Address</td>
                                        <td>{{partner.vehicleData?.present_address}}</td>
                                    </tr>
                                    <tr>
                                        <td>Registered At</td>
                                        <td>{{partner.vehicleData?.registered_at}}</td>
                                    </tr>
                                    <tr>
                                        <td>Vehicle Category</td>
                                        <td>{{partner.vehicleData?.vehicle_category}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
        <!--
            <p-table [value]="vehicleProofs" *ngIf="activeTable === 'vehicleProofs'"  [expandedRowKeys]="expandedRows"
                (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Driving Lincense</th>
                        <th>RC </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-vehicleProofs let-expanded="expanded">
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </ng-template>
            </p-table>
        </div> -->
    </div>
</p-dialog>

<p-dialog [modal]="true" [(visible)]="EditVisible" [style]="{ width: '30rem' , 'height':'30rem' }">
    <div class="d-flex flex-column gap-2">
        <div class="chipSection d-flex justify-content-end">
            <div class="d-flex justify-content-center align-items-center">
                <span class="pi pi-times" (click)="DontshowEditDialog()"></span>
            </div>
        </div>
        <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
            (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
        <div class="orderHistoryCard">
            <div class="accordionField" *ngIf="activeItem!.label == 'Basic Details'">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Basic Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="basicDetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">

                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="firstName">First Name</label>
                                                <input pInputText id="firstName" formControlName="firstName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="lastName">Last Name</label>
                                                <input pInputText id="lastName" formControlName="lastName" />
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="emailId">Email Id</label>
                                            <input pInputText id="emailId" formControlName="emailId" />
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="mobileNumber">Mobile Number</label>
                                            <input pInputText id="mobileNumber" formControlName="mobileNumber" />
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="address">Address</label>
                                            <input pInputText id="address" formControlName="address" />
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="gender">Gender</label>
                                            <input pInputText id="gender" formControlName="gender" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <div class="accordionField" *ngIf="activeItem!.label == 'Id Proofs'">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Aadhaar Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="aadhaarDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="aadhaarNumber">Aadhaar Number</label>
                                            <input pInputText id="aadhaarNumber" formControlName="aadhaarNumber" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="fullName">Full Name</label>
                                                <input pInputText id="fullName" formControlName="fullName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="dateOfBirth">Date of Birth</label>
                                                <input pInputText id="dateOfBirth" formControlName="dateOfBirth" />
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="gender">Gender</label>
                                            <input pInputText id="gender" formControlName="gender" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">PAN Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="PANDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="panNumber">PAN Number</label>
                                            <input pInputText id="panNumber" formControlName="panNumber" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="fullName">Full Name</label>
                                                <input pInputText id="fullName" formControlName="fullName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="category">Category</label>
                                                <input pInputText id="category" formControlName="category" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <div class="accordionField" *ngIf="activeItem!.label == 'Bank Details'">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Bank Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="bankDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2">
                                                <label for="bankAccountNumber">Bank Account Number</label>
                                                <input pInputText id="bankAccountNumber"
                                                    formControlName="bankAccountNumber" />
                                            </div>
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="IFSCCode">IFSCCode</label>
                                                <input pInputText id="IFSCCode" formControlName="IFSCCode" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
            <div class="accordionField" *ngIf="activeItem!.label == 'Vehical Proofs'">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Driving License Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="licenseDetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column  w-50 gap-2">
                                                <label for="fullName">Full Name</label>
                                                <input pInputText id="fullName" formControlName="fullName" />
                                            </div>
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="DOB">Date Of Birth</label>
                                                <input pInputText id="DOB" formControlName="DOB" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="currentStatus">Current Status</label>
                                                <input pInputText id="currentStatus" formControlName="currentStatus" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="DOI">Date of Issue</label>
                                                <input pInputText id="DOI" formControlName="DOI" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="DOE">Date of Expiry</label>
                                                <input pInputText id="DOE" formControlName="DOE" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="licenseNumber">License Number</label>
                                                <input pInputText id="licenseNumber" formControlName="licenseNumber" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="olaCode">OLA Code</label>
                                                <input pInputText id="olaCode" formControlName="olaCode" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="olaName">OLA Name</label>
                                                <input pInputText id="olaName" formControlName="olaName" />
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column  gap-2">
                                            <label for="permanentAddress">Permanent Address</label>
                                                <div class="d-flex flex-column gap-2">
                                                    <textarea rows="5" cols="30" pInputTextarea
                                                        formControlName="permanentAddress"
                                                        id="permanentAddress">
                                                    </textarea>
                                                </div>
                                        </div>
                                        <div class="d-flex flex-column w-50 gap-2">
                                            <label for="vehicleClass">Vehicle Classes</label>
                                            <input pInputText id="vehicleClass" formControlName="vehicleClass" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">RC Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="rcDetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="ownerName">Owner Name</label>
                                            <input pInputText id="ownerName" formControlName="ownerName" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="rcNumber">RC Number</label>
                                                <input pInputText id="rcNumber" formControlName="rcNumber" />
                                            </div>
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="rcStatus">RC Status</label>
                                                <input pInputText id="rcStatus" formControlName="rcStatus" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="insuranceCompany">Insurance Company</label>
                                                <input pInputText id="insuranceCompany"
                                                    formControlName="insuranceCompany" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="insurancePolicyNumber">Insurance Policy Number</label>
                                                <input pInputText id="insurancePolicyNumber"
                                                    formControlName="insurancePolicyNumber" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="insuranceUpto">Insurance Upto</label>
                                                <input pInputText id="insuranceUpto" formControlName="insuranceUpto" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="manufacturer">Manufacturer</label>
                                                <input pInputText id="manufacturer" formControlName="manufacturer" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="model">Model</label>
                                                <input pInputText id="model" formControlName="model" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="chasiNumber">Chasi Number</label>
                                                <input pInputText id="chasiNumber" formControlName="chasiNumber" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="engineNumber">Engine Number</label>
                                                <input pInputText id="engineNumber" formControlName="engineNumber" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="vehicleCategory">Vehicle Category</label>
                                                <input pInputText id="vehicleCategory" formControlName="vehicleCategory" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="registeredAt">Registered At</label>
                                                <input pInputText id="registeredAt" formControlName="registeredAt" />
                                            </div>
                                        </div>
                                       
                                        <div class="d-flex flex-column  gap-2">
                                            <label for="permanentAddress">Permanent Address</label>
                                                <div class="d-flex flex-column gap-2">
                                                    <textarea rows="5" cols="30" pInputTextarea
                                                        formControlName="permanentAddress"
                                                        id="permanentAddress">
                                                    </textarea>
                                                </div>
                                                <div class="d-flex flex-column  gap-2">
                                                    <label for="presentAddress">Present Address</label>
                                                        <div class="d-flex flex-column gap-2">
                                                            <textarea rows="5" cols="30" pInputTextarea
                                                                formControlName="presentAddress"
                                                                id="presentAddress">
                                                            </textarea>
                                                        </div>
                                                </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end gap-2 mt-4 ">
        <p-button label="Confirm" severity="primary" />
    </div>
</p-dialog>

<p-dialog header="Confirm" class="signUpModal" [modal]="true" [(visible)]="showDeleteConfirmationModal"
    [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to delete
        <strong>{{selectedPartner?.deliveryPartnerId}}</strong>.
        Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button label="Confirm" severity="danger" (onClick)="deleteDeliveryPartner(selectedPartner.id)" />
    </div>
</p-dialog>

<p-dialog header="Reasons" [modal]="true" [(visible)]="rejectVisible" [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <form >
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex flex-column gap-2">
                <textarea rows="5" placeholder="Provide valid reason for rejection" cols="30" pInputTextarea >
                </textarea>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Reject" severity="danger"  />
    </div>
</p-dialog>