<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Applications'"></app-title-bar>
    </div>
    <div class="bodySection">
        <p-table [value]="applicationOwners" dataKey="ownerId" [expandedRowKeys]="expandedRows" [loading]="dataLoading"
            (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th>Owner ID</th>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-owner let-expanded="expanded">
                <tr>
                    <td>
                        <p-button type="button" pButton [rounded]="true" severity="secondary" pRipple
                            [pRowToggler]="owner" class="p-button-text p-button-rounded p-button-plain" 
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></p-button>
                    </td>
                    <td>{{ owner?.ownerId }}</td>
                    <td>{{ owner?.firstName }} {{ owner?.lastName }}</td>
                    <td>{{ owner?.mobile }}</td>
                    <td>{{ owner?.email }}</td>
                    <td>
                        <div class="d-flex gap-2">
                            <span *ngIf="showAnimationContainer" #animationContainer [attr.data-animation-type]="'edit'"
                                [attr.data-location-id]="owner?.ownerId" class="d-flex animEditIcon">
                            </span>
                            <span *ngIf="showAnimationContainer" #animationContainer [attr.data-animation-type]="'send'"
                                [attr.data-location-id]="owner?.ownerId" class="d-flex animSendIcon">
                            </span>
                            <span *ngIf="showAnimationContainer" #animationContainer
                                [attr.data-animation-type]="'trash'" [attr.data-location-id]="owner?.ownerId"
                                class="d-flex animTrashIcon" (click)="deleteOwner(owner)">
                            </span>
                            <!-- <span *ngIf="showAnimationContainer" #animationContainer
                                [attr.data-animation-type]="'trash'" [attr.data-location-id]="owner?.ownerId"
                                class="d-flex animTrashIcon" (click)="showApproveDialog(owner, application)">
                            </span> -->
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-owner>
                <tr>
                    <td colspan="6">
                        <p-table [value]="owner.openOutletApplications" *ngIf="owner.openOutletApplications.length > 0">
                            <ng-template pTemplate="header">
                <tr>
                    <th>Application ID</th>
                    <th>Outlet Name</th>
                    <th>Outlet Location</th>
                    <th>Outlet Category</th>
                    <th>Current Step</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-application>
                <tr>
                    <td>{{ application.applicationId }}</td>
                    <td>{{ application.outletDetails?.outletName ? application.outletDetails?.outletName : '-' }}</td>
                    <td>
                        <ng-container *ngIf="application.outletDetails?.outletLocation">
                            {{ application.outletDetails.outletLocation.outletLatitude }},
                            <br>
                            {{ application.outletDetails.outletLocation.outletLongitude }}
                            <br>
                            <a href=""
                                (click)="openInMaps(application.outletDetails.outletLocation.outletLatitude, application.outletDetails.outletLocation.outletLongitude)"
                                target="_blank">View in Maps</a>
                        </ng-container>
                        <ng-container *ngIf="!application.outletDetails?.outletLocation">
                            -
                        </ng-container>
                    </td>
                    <td>{{ application.outletDetails?.outletCategory ? application.outletDetails?.outletCategory : '-'
                        }}</td>
                    <td>{{ application.currentStep }}</td>
                    <td>
                        <span class="statusPill" [ngClass]="{
                            'activePill': application.applicationStatus === 'open',
                            'pendingPill': application.applicationStatus === 'pending',
                            'rejectedPill': application.applicationStatus === 'rejected',
                            'disabledPill': application.applicationStatus === 'disabled'
                            }">
                            {{ application.applicationStatus }}
                        </span>
                    </td>
                    <td>
                        <div class="d-flex gap-2">
                            <span *ngIf="showAnimationContainer" #expansionContainer [attr.data-animation-type]="'info'"
                                [attr.data-location-id]="application.applicationId" class="d-flex animInfoIcon"
                                (click)="showDialog(owner, application)"></span>
                            <span *ngIf="showAnimationContainer" #expansionContainer
                                [attr.data-animation-type]="'check'" [attr.data-location-id]="application.applicationId"
                                class="d-flex animCheckIcon" (click)="approveOutlet(application.outletDetails)"></span>
                            <span *ngIf="showAnimationContainer" #expansionContainer [attr.data-animation-type]="'edit'"
                                [attr.data-location-id]="application.applicationId" class="d-flex animEditIcon"
                                (click)="showEditDialog(owner, application)"></span>
                            <span *ngIf="showAnimationContainer" #expansionContainer
                                [attr.data-animation-type]="'pause'" [attr.data-location-id]="application.applicationId"
                                class="d-flex animPauseIcon"></span>
                            <span *ngIf="showAnimationContainer" #expansionContainer
                                [attr.data-animation-type]="'trash'" [attr.data-location-id]="application.applicationId"
                                class="d-flex animTrashIcon" (click)="deleteApplication(owner, application)"></span>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div *ngIf="owner.openOutletApplications.length === 0">No open applications</div>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [header]="selectedApplication?.length > 0 ? selectedApplication[0].applicationId + ' | Details' : ''"
    closable="true" [modal]="true" [draggable]="false" [(visible)]="visible"
    [style]="{ width: '30rem' , 'height':'30rem' }" (onHide)="DontshowDialog()">
    <div class="d-flex flex-column gap-2">
        <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
            (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
        <div class="orderHistoryCard" *ngIf="activeItem!.label == 'Owner Details'">
            <div class="accordionField">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Basic Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <p-table [value]="selectedOwner" styleClass="p-datatable-striped" dataKey="ownerId"
                                    [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
                                    (onRowCollapse)="onRowCollapse($event)">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-owner let-expanded="expanded">
                                        <tr>
                                            <td>Owner Id</td>
                                            <td>{{ owner.ownerId }}</td>
                                        </tr>
                                        <tr>
                                            <td>First Name</td>
                                            <td>{{ owner.firstName }}</td>
                                        </tr>
                                        <tr>
                                            <td>Last Name</td>
                                            <td>{{ owner.lastName }}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{{ owner.email }}</td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Number</td>
                                            <td>{{ owner.mobile }}</td>
                                        </tr>
                                        <tr>
                                            <td>Is Aadhaar Verified</td>
                                            <td>
                                                <span class="isIcon pi" [ngClass]="{
                                                'pi-check':  owner.isAadhaarVerified  === true,
                                                'pi-times': owner.isAadhaarVerified === false
                                                }">
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Is Bank Verified</td>
                                            <td>
                                                <span class="isIcon pi" [ngClass]="{
                                                    'pi-check':  owner.isBankVerified  === true,
                                                    'pi-times': owner.isBankVerified === false
                                                    }">
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Is Pan Verified</td>
                                            <td>
                                                <span class="isIcon pi" [ngClass]="{
                                                    'pi-check':  owner.isPanVerified  === true,
                                                    'pi-times': owner.isPanVerified === false
                                                    }">
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Is Payment Done</td>
                                            <td>
                                                <span class="isIcon pi" [ngClass]="{
                                                    'pi-check': owner.isPaymentDone  === true,
                                                    'pi-times': owner.isPaymentDone === false
                                                    }">
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Is Restaurant Approved </td>
                                            <td>
                                                <span class="isIcon pi" [ngClass]="{
                                                    'pi-check':  owner.isRestaurantApproved  === true,
                                                    'pi-times': owner.isRestaurantApproved === false
                                                    }">
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Aadhaar Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <p-table [value]="selectedOwner" styleClass="p-datatable-striped" dataKey="ownerId"
                                    [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
                                    (onRowCollapse)="onRowCollapse($event)">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-owner let-expanded="expanded">
                                        <tr>
                                            <td>Profile</td>
                                            <td>
                                                <div style="width: 200px; height: 200px; border-radius: 10px;">
                                                    <img [src]="'data:image/jpeg;base64,' + owner.aadhaarData.profile_image"
                                                        alt="Profile Image"
                                                        style="width: 100%; height: 100%; object-fit: contain;">
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Aadhaar Number</td>
                                            <td>{{ owner.aadhaarData.aadhaar_number }}</td>
                                        </tr>
                                        <tr>
                                            <td>Full Name</td>
                                            <td>{{ owner.aadhaarData.full_name }}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Birth</td>
                                            <td>{{ owner.aadhaarData.dob }}</td>
                                        </tr>
                                        <tr>
                                            <td>Gender</td>
                                            <td>{{ owner.aadhaarData.gender }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">PAN Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <p-table [value]="selectedOwner" dataKey="ownerId" [expandedRowKeys]="expandedRows"
                                    styleClass="p-datatable-striped" (onRowExpand)="onRowExpand($event)"
                                    (onRowCollapse)="onRowCollapse($event)">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-owner let-expanded="expanded">
                                        <tr>
                                            <td>PAN Number</td>
                                            <td>{{ owner.panData.pan_number }}</td>
                                        </tr>
                                        <tr>
                                            <td>Full Name</td>
                                            <td>{{ owner.panData.full_name }}</td>
                                        </tr>
                                        <tr>
                                            <td>Category</td>
                                            <td>{{ owner.panData.category }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Bank Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <p-table [value]="selectedOwner" dataKey="ownerId" [expandedRowKeys]="expandedRows"
                                    styleClass="p-datatable-striped" (onRowExpand)="onRowExpand($event)"
                                    (onRowCollapse)="onRowCollapse($event)">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-owner let-expanded="expanded">
                                        <tr>
                                            <td>Bank Account Number</td>
                                            <td>{{ owner.primaryBankAccData.bankAccNumber }}</td>
                                        </tr>
                                        <tr>
                                            <td>IFSC Code</td>
                                            <td>{{ owner.primaryBankAccData.bankIFSC }}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="orderHistoryCard" *ngIf="activeItem!.label == 'Application Details'">
            <div class="accordionField">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Application Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <p-table [value]="selectedApplication" styleClass="p-datatable-striped"
                                    dataKey="ownerId" [expandedRowKeys]="expandedRows"
                                    (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-application let-expanded="expanded">
                                        <tr>
                                            <td>Application Id</td>
                                            <td>{{ application.applicationId }}</td>
                                        </tr>
                                        <tr>
                                            <td>Current Step</td>
                                            <td>{{ application.currentStep }}</td>
                                        </tr>
                                        <tr>
                                            <td>Is FSSAI Submitted</td>
                                            <td>
                                                <span class="isIcon pi" [ngClass]="{
                                                    'pi-check':  application.isFSSAISubmitted  === true,
                                                    'pi-times': application.isFSSAISubmitted === false
                                                    }">
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Is GST Submitted</td>
                                            <td>
                                                <span class="isIcon pi" [ngClass]="{
                                                    'pi-check':  application.isGSTSubmitted === true,
                                                    'pi-times': application.isGSTSubmitted === false
                                                    }">
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Is Udyam Submitted</td>
                                            <td>
                                                <span class="isIcon pi" [ngClass]="{
                                                    'pi-check':  application.isUdyamSubmitted === true,
                                                    'pi-times': application.isUdyamSubmitted === false
                                                    }">
                                                </span>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Outlet Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <p-table [value]="selectedApplication" styleClass="p-datatable-striped"
                                    dataKey="ownerId" [expandedRowKeys]="expandedRows"
                                    (onRowExpand)="onRowExpand($event)" (onRowCollapse)="onRowCollapse($event)">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-application let-expanded="expanded">
                                        <tr>
                                            <td>Outlet Category</td>
                                            <td>{{ application.outletDetails?.outletCategory }}</td>
                                        </tr>
                                        <tr>
                                            <td>Outlet Locality</td>
                                            <td>{{ application.outletDetails?.outletLocality}}</td>
                                        </tr>
                                        <tr>
                                            <td>Outlet Location</td>
                                            <td>{{ application.outletDetails.outletLocation.outletLatitude }},
                                                <br>
                                                {{ application.outletDetails.outletLocation.outletLongitude }}
                                                <br>
                                                <a href=""
                                                    (click)="openInMaps(application.outletDetails.outletLocation.outletLatitude, application.outletDetails.outletLocation.outletLongitude)"
                                                    target="_blank">View in Maps</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Outlet Name</td>
                                            <td>{{ application.outletDetails?.outletName}}</td>
                                        </tr>
                                        <tr>
                                            <td>price For Two</td>
                                            <td>{{ application.outletDetails?.priceForTwo}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [header]="selectedApplication?.length > 0 ? selectedApplication[0].applicationId + ' | Edit' : ''"
    [modal]="true" closable="true" [draggable]="false" [(visible)]="EditVisible">
    <div class="d-flex flex-column gap-2 dialogContent">
        <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
            (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
        <div class="orderHistoryCard" *ngIf="activeItem!.label == 'Owner Details'">
            <div class="accordionField">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Basic Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="basicDetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="ownerId">Owner Id</label>
                                            <input pInputText id="ownerId" formControlName="ownerId" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="firstName">First Name</label>
                                                <input pInputText id="firstName" formControlName="firstName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="lastName">Last Name</label>
                                                <input pInputText id="lastName" formControlName="lastName" />
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="emailId">Email Id</label>
                                            <input pInputText id="emailId" formControlName="emailId" />
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="mobileNumber">Mobile Number</label>
                                            <input pInputText id="mobileNumber" formControlName="mobileNumber" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Aadhaar Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="aadhaarDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="aadhaarNumber">Aadhaar Number</label>
                                            <input pInputText id="aadhaarNumber" formControlName="aadhaarNumber" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="fullName">Full Name</label>
                                                <input pInputText id="fullName" formControlName="fullName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="dateOfBirth">Date of Birth</label>
                                                <input pInputText id="dateOfBirth" formControlName="dateOfBirth" />
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="gender">Gender</label>
                                            <input pInputText id="gender" formControlName="gender" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">PAN Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="PANDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="panNumber">PAN Number</label>
                                            <input pInputText id="panNumber" formControlName="panNumber" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="fullName">Full Name</label>
                                                <input pInputText id="fullName" formControlName="fullName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="category">Category</label>
                                                <input pInputText id="category" formControlName="category" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Bank Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="bankDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2">
                                                <label for="bankAccountNumber">Bank Account Number</label>
                                                <input pInputText id="bankAccountNumber"
                                                    formControlName="bankAccountNumber" />
                                            </div>
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="IFSCCode">IFSCCode</label>
                                                <input pInputText id="IFSCCode" formControlName="IFSCCode" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="orderHistoryCard" *ngIf="activeItem!.label == 'Application Details'">
            <div class="accordionField">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Application Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="applicationDetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2">
                                                <label for="applicationId">Application Id</label>
                                                <input pInputText id="applicationId" formControlName="applicationId" />
                                            </div>
                                            <div class="p-field d-flex flex-column gap-2 w-50">
                                                <label for="currentStep">Current Step</label>
                                                <p-dropdown id="currentStep" [options]="steps" optionLabel="name" formControlName="currentStep" placeholder="Current Step" optionValue="value"></p-dropdown>
                                            </div>  
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="p-field d-flex flex-column gap-2 ">
                                                <label for="isFSSAISubmitted">Is FSSAI Submitted</label>
                                                <p-dropdown id="isFSSAISubmitted" [options]="fssai" optionLabel="name"  optionValue="value" formControlName="isFSSAISubmitted" placeholder="Is FSSAI Submitted"></p-dropdown>
                                            </div> 
                                            <div class="p-field d-flex flex-column gap-2">
                                                <label for="isGSTSubmitted">Is GST Submitted</label>
                                                <p-dropdown id="isGSTSubmitted" [options]="gst" optionLabel="name"  optionValue="value" formControlName="isGSTSubmitted" placeholder="Is GST Submitted" ></p-dropdown>
                                            </div> 
                                        </div>
                                        <div class="p-field d-flex flex-column gap-2">
                                            <label for="isUdyamSubmitted">Is Udyam Submitted</label>
                                            <p-dropdown id="isUdyamSubmitted" [options]="udyam" optionLabel="name"  optionValue="value" formControlName="isUdyamSubmitted" placeholder="Is Udyam Submitted"></p-dropdown>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Outlet Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="outletdetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="outletName">Outlet Name</label>
                                            <input pInputText id="outletName" formControlName="outletName" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2">
                                                <label for="outletCategory">Outlet Category</label>
                                                <input pInputText id="outletCategory"
                                                    formControlName="outletCategory" />
                                            </div>
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="priceForTwo">Price For Two</label>
                                                <input pInputText id="priceForTwo" formControlName="priceForTwo" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="outletLocation">Outlet Location</label>
                                                <input pInputText id="outletLocation"
                                                    formControlName="outletLocation" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="outletLocality">Outlet Locality</label>
                                                <input pInputText id="outletLocality"
                                                    formControlName="outletLocality" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="d-flex justify-content-end gap-2 mt-4">
            <div class="mb-2">
                <p-button label="Update" severity="primary" />
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog [header]="selectedApplication?.length > 0 ? selectedApplication[0].applicationId + ' | Approve' : ''" [modal]="true" closable="true" [draggable]="false" [(visible)]="ApproveVisible">
    <div class="d-flex flex-column gap-2 dialogContent">
        <p-tabMenu styleClass="tabMenu" [model]="tabMenuItems" [activeItem]="activeItem"
            (activeItemChange)="onActiveItemChange($event)"></p-tabMenu>
        <div class="orderHistoryCard" *ngIf="activeItem!.label == 'Owner Details'">
            <div class="accordionField">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Basic Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="basicDetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="ownerId">Owner Id</label>
                                            <input pInputText id="ownerId" formControlName="ownerId" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="firstName">First Name</label>
                                                <input pInputText id="firstName" formControlName="firstName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="lastName">Last Name</label>
                                                <input pInputText id="lastName" formControlName="lastName" />
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="emailId">Email Id</label>
                                            <input pInputText id="emailId" formControlName="emailId" />
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="mobileNumber">Mobile Number</label>
                                            <input pInputText id="mobileNumber" formControlName="mobileNumber" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Aadhaar Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="aadhaarDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="aadhaarNumber">Aadhaar Number</label>
                                            <input pInputText id="aadhaarNumber" formControlName="aadhaarNumber" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="fullName">Full Name</label>
                                                <input pInputText id="fullName" formControlName="fullName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="dateOfBirth">Date of Birth</label>
                                                <input pInputText id="dateOfBirth" formControlName="dateOfBirth" />
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column gap-2">
                                            <label for="gender">Gender</label>
                                            <input pInputText id="gender" formControlName="gender" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">PAN Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="PANDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="panNumber">PAN Number</label>
                                            <input pInputText id="panNumber" formControlName="panNumber" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="fullName">Full Name</label>
                                                <input pInputText id="fullName" formControlName="fullName" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="category">Category</label>
                                                <input pInputText id="category" formControlName="category" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Bank Data</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="bankDataForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2">
                                                <label for="bankAccountNumber">Bank Account Number</label>
                                                <input pInputText id="bankAccountNumber"
                                                    formControlName="bankAccountNumber" />
                                            </div>
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="IFSCCode">IFSCCode</label>
                                                <input pInputText id="IFSCCode" formControlName="IFSCCode" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="orderHistoryCard" *ngIf="activeItem!.label == 'Application Details'">
            <div class="accordionField">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Application Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="applicationDetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2">
                                                <label for="applicationId">Application Id</label>
                                                <input pInputText id="applicationId" formControlName="applicationId" />
                                            </div>
                                            <div class="p-field d-flex flex-column gap-2 w-50">
                                                <label for="currentStep">Current Step</label>
                                                <p-dropdown id="currentStep" [options]="steps" optionLabel="name" formControlName="currentStep" placeholder="Current Step" optionValue="value"></p-dropdown>
                                            </div>  
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="p-field d-flex flex-column gap-2 ">
                                                <label for="isFSSAISubmitted">Is FSSAI Submitted</label>
                                                <p-dropdown id="isFSSAISubmitted" [options]="fssai" optionLabel="name"  optionValue="value" formControlName="isFSSAISubmitted" placeholder="Is FSSAI Submitted"></p-dropdown>
                                            </div> 
                                            <div class="p-field d-flex flex-column gap-2">
                                                <label for="isGSTSubmitted">Is GST Submitted</label>
                                                <p-dropdown id="isGSTSubmitted" [options]="gst" optionLabel="name"  optionValue="value" formControlName="isGSTSubmitted" placeholder="Is GST Submitted" ></p-dropdown>
                                            </div> 
                                        </div>
                                        <div class="p-field d-flex flex-column gap-2">
                                            <label for="isUdyamSubmitted">Is Udyam Submitted</label>
                                            <p-dropdown id="isUdyamSubmitted" [options]="udyam" optionLabel="name"  optionValue="value" formControlName="isUdyamSubmitted" placeholder="Is Udyam Submitted"></p-dropdown>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Outlet Details</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="detailsInfo">
                                <form [formGroup]="outletdetailsForm">
                                    <div class="d-flex flex-column gap-3 mb-4">
                                        <div class="d-flex flex-column gap-2">
                                            <label for="outletName">Outlet Name</label>
                                            <input pInputText id="outletName" formControlName="outletName" />
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column gap-2">
                                                <label for="outletCategory">Outlet Category</label>
                                                <input pInputText id="outletCategory"
                                                    formControlName="outletCategory" />
                                            </div>
                                            <div class="d-flex flex-column gap-2 w-50">
                                                <label for="priceForTwo">Price For Two</label>
                                                <input pInputText id="priceForTwo" formControlName="priceForTwo" />
                                            </div>
                                        </div>
                                        <div class="d-flex gap-4">
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="outletLocation">Outlet Location</label>
                                                <input pInputText id="outletLocation"
                                                    formControlName="outletLocation" />
                                            </div>
                                            <div class="d-flex flex-column w-50 gap-2">
                                                <label for="outletLocality">Outlet Locality</label>
                                                <input pInputText id="outletLocality"
                                                    formControlName="outletLocality" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
        <div class="d-flex justify-content-end gap-2 mt-4">
            <div class="mb-2">
                <!-- <p-button label="Approve" severity="primary" (click)="approveOutlet(application.outletDetails)"/> -->
                <p-button label="Approve" severity="primary"/>
            </div>
        </div>
    </div>
</p-dialog>

<p-dialog header="Reasons" [modal]="true" [(visible)]="rejectVisible" [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <form [formGroup]="rejectForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex flex-column gap-2">
                <label for="rejectArea">Enter Reason</label>
                <textarea rows="5" placeholder="Provide valid reason for rejection" cols="30" id="rejectArea" pInputTextarea formControlName="rejectionComment">
                </textarea>
            </div>
        </div>
        <div class="p-field d-flex flex-column gap-2 w-50">
            <label for="currentStep">Current Step</label>
            <p-dropdown id="currentStep" [options]="steps" optionLabel="name" formControlName="currentStep" placeholder="Current Step"></p-dropdown>
        </div>  
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button label="Reject" severity="danger"  />
    </div>
</p-dialog>

<p-dialog header="Confirm" [modal]="true" [(visible)]="deleteVisible"
    [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to delete.
        Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button label="Confirm" severity="danger"/>
        <!-- <p-button label="Confirm" severity="danger" (click)="deleteApplication(owner, application)"/> -->
    </div>
</p-dialog>