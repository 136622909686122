<div class="mainContainer h-100 w-100">
    <div class="titleSection d-flex justify-content-between align-items-center">
        <span>
            {{activeNav}} <span *ngIf="activeAction">| {{activeAction}}</span>
        </span>
        <div class="notificationIconHolder" (click)="notificationPanel.toggle($event)">
            <i class="pi pi-bell text-3xl" pBadge [value]="notifications.length"></i>
        </div>
    </div>

    <p-overlayPanel #notificationPanel [showCloseIcon]="false">
        <ng-template pTemplate>
            <div *ngIf="notifications.length > 0; else noNotifications"
                class="notificationDiv d-flex flex-column gap-2">
                <div *ngFor="let notification of notifications" class="notificationItem ">
                    <div class="d-flex align-items-center gap-3 flex-column"
                         [ngClass]="{'successNotification': notification.severity == 'success', 'warningNotification': notification.severity == 'warn', 'deleteNotification': notification.severity == 'danger'}">
                        <div class="d-flex align-items-center gap-3">
                            <div class="d-flex flex-column">
                                <span class="notificationTitle px-2 d-flex align-items-center gap-2"><span class="pi"  [ngClass]="{
                                    'warnIcon pi-exclamation-triangle': notification.severity === 'warn',
                                    'successIcon pi-check-circle': notification.severity === 'success',
                                    'dangerIcon pi-times': notification.severity === 'danger'
                                  }"></span> {{ notification.notificationTitle }}</span>
                                <span class="notificationDescription text-muted">{{ notification.notificationDescription
                                    }}</span>
                            </div>
                        </div>
                        <div class="d-flex w-100 justify-content-between align-items-center">
                            <div class="notificationTime">
                                {{ notification.notificationTime| date:'dd MMM yyyy, hh:mm a' }}
                            </div>
                            <div class="actionPanel">
                                <span
                                    (click)="clearNotification(notification.id, $event, notificationPanel)">Clear</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearPanel d-flex justify-content-end">
                <span (click)="clearAllNotifications(notificationPanel)">Clear All</span>
            </div>
            <ng-template #noNotifications>
                <div class="emptyMessage">
                    <div class="h-100 w-100 d-flex justify-content-center align-items-center">
                        No new notifications
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </p-overlayPanel>
</div>