import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, Component, Inject, ViewChild, Output, forwardRef, Input, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { CommonModule } from '@angular/common';
const _c0 = ["editorContainer"];
const NGX_MONACO_EDITOR_CONFIG = new InjectionToken('NGX_MONACO_EDITOR_CONFIG');
let loadedMonaco = false;
let loadPromise;
class BaseEditor {
  constructor(config) {
    this.config = config;
    this.onInit = new EventEmitter();
  }
  ngAfterViewInit() {
    if (loadedMonaco) {
      // Wait until monaco editor is available
      loadPromise.then(() => {
        this.initMonaco(this._options);
      });
    } else {
      loadedMonaco = true;
      loadPromise = new Promise(resolve => {
        const baseUrl = (this.config.baseUrl || './assets') + '/monaco-editor/min/vs';
        if (typeof window.monaco === 'object') {
          resolve();
          return;
        }
        const onGotAmdLoader = () => {
          // Load monaco
          window.require.config({
            paths: {
              'vs': `${baseUrl}`
            }
          });
          window.require([`vs/editor/editor.main`], () => {
            if (typeof this.config.onMonacoLoad === 'function') {
              this.config.onMonacoLoad();
            }
            this.initMonaco(this._options);
            resolve();
          });
        };
        // Load AMD loader if necessary
        if (!window.require) {
          const loaderScript = document.createElement('script');
          loaderScript.type = 'text/javascript';
          loaderScript.src = `${baseUrl}/loader.js`;
          loaderScript.addEventListener('load', onGotAmdLoader);
          document.body.appendChild(loaderScript);
        } else {
          onGotAmdLoader();
        }
      });
    }
  }
  ngOnDestroy() {
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    if (this._editor) {
      this._editor.dispose();
      this._editor = undefined;
    }
  }
}
BaseEditor.ɵfac = function BaseEditor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BaseEditor)(i0.ɵɵdirectiveInject(NGX_MONACO_EDITOR_CONFIG));
};
BaseEditor.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BaseEditor,
  selectors: [["ng-component"]],
  viewQuery: function BaseEditor_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx._editorContainer = _t.first);
    }
  },
  outputs: {
    onInit: "onInit"
  },
  decls: 0,
  vars: 0,
  template: function BaseEditor_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseEditor, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGX_MONACO_EDITOR_CONFIG]
      }]
    }];
  }, {
    _editorContainer: [{
      type: ViewChild,
      args: ['editorContainer', {
        static: true
      }]
    }],
    onInit: [{
      type: Output
    }]
  });
})();
class EditorComponent extends BaseEditor {
  constructor(zone, editorConfig) {
    super(editorConfig);
    this.zone = zone;
    this.editorConfig = editorConfig;
    this._value = '';
    this.propagateChange = _ => {};
    this.onTouched = () => {};
  }
  set options(options) {
    this._options = Object.assign({}, this.config.defaultOptions, options);
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(options);
    }
  }
  get options() {
    return this._options;
  }
  set model(model) {
    this.options.model = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options);
    }
  }
  writeValue(value) {
    this._value = value || '';
    // Fix for value change while dispose in process.
    setTimeout(() => {
      if (this._editor && !this.options.model) {
        this._editor.setValue(this._value);
      }
    });
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  initMonaco(options) {
    const hasModel = !!options.model;
    if (hasModel) {
      const model = monaco.editor.getModel(options.model.uri || '');
      if (model) {
        options.model = model;
        options.model.setValue(this._value);
      } else {
        options.model = monaco.editor.createModel(options.model.value, options.model.language, options.model.uri);
      }
    }
    this._editor = monaco.editor.create(this._editorContainer.nativeElement, options);
    if (!hasModel) {
      this._editor.setValue(this._value);
    }
    this._editor.onDidChangeModelContent(e => {
      const value = this._editor.getValue();
      // value is not propagated to parent when executing outside zone.
      this.zone.run(() => {
        this.propagateChange(value);
        this._value = value;
      });
    });
    this._editor.onDidBlurEditorWidget(() => {
      this.onTouched();
    });
    // refresh layout on resize event.
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    this._windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => this._editor.layout());
    this.onInit.emit(this._editor);
  }
}
EditorComponent.ɵfac = function EditorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || EditorComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(NGX_MONACO_EDITOR_CONFIG));
};
EditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: EditorComponent,
  selectors: [["ngx-monaco-editor"]],
  inputs: {
    options: "options",
    model: "model"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorComponent),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["editorContainer", ""], [1, "editor-container"]],
  template: function EditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 1, 0);
    }
  },
  styles: ["[_nghost-%COMP%] {\n          display: block;\n          height: 200px;\n      }\n\n      .editor-container[_ngcontent-%COMP%] {\n          width: 100%;\n          height: 98%;\n      }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-monaco-editor',
      template: '<div class="editor-container" #editorContainer></div>',
      styles: [`
      :host {
          display: block;
          height: 200px;
      }

      .editor-container {
          width: 100%;
          height: 98%;
      }
  `],
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EditorComponent),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGX_MONACO_EDITOR_CONFIG]
      }]
    }];
  }, {
    options: [{
      type: Input,
      args: ['options']
    }],
    model: [{
      type: Input,
      args: ['model']
    }]
  });
})();
class DiffEditorComponent extends BaseEditor {
  constructor(editorConfig) {
    super(editorConfig);
    this.editorConfig = editorConfig;
  }
  set options(options) {
    this._options = Object.assign({}, this.config.defaultOptions, options);
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(options);
    }
  }
  get options() {
    return this._options;
  }
  set originalModel(model) {
    this._originalModel = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options);
    }
  }
  set modifiedModel(model) {
    this._modifiedModel = model;
    if (this._editor) {
      this._editor.dispose();
      this.initMonaco(this.options);
    }
  }
  initMonaco(options) {
    if (!this._originalModel || !this._modifiedModel) {
      throw new Error('originalModel or modifiedModel not found for ngx-monaco-diff-editor');
    }
    this._originalModel.language = this._originalModel.language || options.language;
    this._modifiedModel.language = this._modifiedModel.language || options.language;
    let originalModel = monaco.editor.createModel(this._originalModel.code, this._originalModel.language);
    let modifiedModel = monaco.editor.createModel(this._modifiedModel.code, this._modifiedModel.language);
    this._editorContainer.nativeElement.innerHTML = '';
    const theme = options.theme;
    this._editor = monaco.editor.createDiffEditor(this._editorContainer.nativeElement, options);
    options.theme = theme;
    this._editor.setModel({
      original: originalModel,
      modified: modifiedModel
    });
    // refresh layout on resize event.
    if (this._windowResizeSubscription) {
      this._windowResizeSubscription.unsubscribe();
    }
    this._windowResizeSubscription = fromEvent(window, 'resize').subscribe(() => this._editor.layout());
    this.onInit.emit(this._editor);
  }
}
DiffEditorComponent.ɵfac = function DiffEditorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DiffEditorComponent)(i0.ɵɵdirectiveInject(NGX_MONACO_EDITOR_CONFIG));
};
DiffEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DiffEditorComponent,
  selectors: [["ngx-monaco-diff-editor"]],
  inputs: {
    options: "options",
    originalModel: "originalModel",
    modifiedModel: "modifiedModel"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 0,
  consts: [["editorContainer", ""], [1, "editor-container"]],
  template: function DiffEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 1, 0);
    }
  },
  styles: ["[_nghost-%COMP%] {\n      display: block;\n      height: 200px;\n    }\n\n    .editor-container[_ngcontent-%COMP%] {\n      width: 100%;\n      height: 98%;\n    }"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DiffEditorComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-monaco-diff-editor',
      template: '<div class="editor-container" #editorContainer></div>',
      styles: [`
    :host {
      display: block;
      height: 200px;
    }

    .editor-container {
      width: 100%;
      height: 98%;
    }
  `]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGX_MONACO_EDITOR_CONFIG]
      }]
    }];
  }, {
    options: [{
      type: Input,
      args: ['options']
    }],
    originalModel: [{
      type: Input,
      args: ['originalModel']
    }],
    modifiedModel: [{
      type: Input,
      args: ['modifiedModel']
    }]
  });
})();
class MonacoEditorModule {
  static forRoot(config = {}) {
    return {
      ngModule: MonacoEditorModule,
      providers: [{
        provide: NGX_MONACO_EDITOR_CONFIG,
        useValue: config
      }]
    };
  }
}
MonacoEditorModule.ɵfac = function MonacoEditorModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MonacoEditorModule)();
};
MonacoEditorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MonacoEditorModule
});
MonacoEditorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MonacoEditorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [EditorComponent, DiffEditorComponent],
      exports: [EditorComponent, DiffEditorComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DiffEditorComponent, EditorComponent, MonacoEditorModule, NGX_MONACO_EDITOR_CONFIG };
