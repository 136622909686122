import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  templateCollection: AngularFirestoreCollection;
  

  constructor(private fireStore: AngularFirestore) { 
    this.templateCollection = fireStore.collection('templates');
  }

  addTemplate(obj: any) {
    return this.templateCollection.doc().set(obj);
  }

  updateTemplate(templateId: any, obj: any) {
    return this.templateCollection.doc(templateId).set(obj, { merge: true }); 
  }

  getTemplates() {
    return this.templateCollection.get();
  }

  deleteTemplate(templateId: any) {
    return this.templateCollection.doc(templateId).delete();
  }
}
