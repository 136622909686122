/**
 * @internal
 */
export function isThemeColor(o) {
  return o && typeof o.id === 'string';
}
/**
 * The type of the `IEditor`.
 */
export const EditorType = {
  ICodeEditor: 'vs.editor.ICodeEditor',
  IDiffEditor: 'vs.editor.IDiffEditor'
};