<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer h-100 w-100">
    <div>
        <app-title-bar [activeNav]="'Access Manager'"></app-title-bar>
    </div>
    <div class="bodySection">
        <div class="mb-3 d-flex justify-content-end">
            <span>
                <p-button label="Add Access Role" icon="pi pi-plus" (onClick)="toggleAddAccessRoleModal()" />
            </span>
        </div>
        <p-table [value]="accessRoles" dataKey="id" [loading]="isTableLoading">
            <ng-template pTemplate="header">
                <tr>
                    <th>Access Role Name</th>
                    <th>Access Group List</th>
                    <th>Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-accessGroup let-expanded="expanded">
                <tr>
                    <td>{{ accessGroup.accessRoleName }}</td>
                    <td>{{ getFeatureNames(accessGroup.accessGroupList) }}</td>
                    <td class="d-flex gap-2">
                        <span *ngIf="showAnimationContainer" #animationContainer [attr.data-animation-type]="'edit'"
                            [attr.data-location-id]="accessGroup.accessRoleName" class="d-flex animEditIcon"
                            (click)="toggleAddAccessRoleModal(accessGroup)">
                        </span>
                        <span *ngIf="showAnimationContainer" #animationContainer [attr.data-animation-type]="'trash'"
                            [attr.data-location-id]="accessGroup.accessRoleName" class="d-flex animTrashIcon"
                            (click)="toggleDeleteLocationConfirmation(accessGroup)">
                        </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">
                        <div class="d-flex justify-content-center">
                            No access roles found
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-dialog [header]="updateAMG ? 'Update Access Role' : 'Add Access Role'" class="signUpModal" [modal]="true"
    [style]="{ width: '400px' }" [(visible)]="showAddAccessRoleModal" [draggable]="false" [closable]="true">
    <form [formGroup]="addAccessRoleForm">
        <div class="d-flex flex-column gap-3 mb-4">
            <div class="d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-100">
                    <label for="accessRoleName">Access Role Name</label>
                    <input pInputText id="accessRoleName" formControlName="accessRoleName" />
                </div>
            </div>
            <div class="d-flex gap-4">
                <div class="d-flex flex-column gap-2 w-100">
                    <label for="accessGroupList">Feature Icon</label>
                    <p-multiSelect appendTo="body" [options]="navItems" formControlName="accessGroupList"
                        optionLabel="featureName" placeholder="Select accessible features" />
                </div>
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end gap-2">
        <p-button [disabled]="addAccessRoleForm.invalid" [label]="updateAMG ? 'Update' : 'Add'"
            (onClick)="addNewAccessRole()" />
    </div>
</p-dialog>

<p-dialog header="Confirm" class="signUpModal" [modal]="true" [(visible)]="showDeleteConfirmationModal"
    [draggable]="false" [closable]="true" [style]="{ width: '30vw' }">
    <span class="p-text-secondary block">You are about to delete <strong>{{selectedAMG?.accessRoleName}}</strong>.
        Are you sure ?</span>
    <div class="d-flex justify-content-end gap-2 mt-4">
        <p-button label="Confirm" severity="danger" (onClick)="deleteAMG(selectedAMG.id)" />
    </div>
</p-dialog>