<div class="mainContainer">
    <div><app-title-bar [activeNav]="'Dashboard'"> </app-title-bar></div>
    <div class="dashboard">
        <div *ngIf="activeAction == 'Orders'" class="orders">
            <div class="card-container">
                <div class="card" id="todayOrders">
                    <h3>Today Orders</h3>
                    <p class="count">20 Oders</p>
                    <span class="info">
                        <span class="highlight">+50%</span> more than yesterday
                    </span>
                </div>
                <div class="card" id="monthlyOrders">
                    <h3>Monthly Orders</h3>
                    <p class="count">320 Orders</p>
                    <span class="info">
                        <span class="highlight">+50%</span> more than yesterday
                    </span>
                </div>
                <div class="card" id="totalOrders">
                    <h3>Total Orders</h3>
                    <p class="count">2220 Orders</p>
                    <span class="info">
                        <span class="highlight">+50%</span> more than last month
                    </span>
                </div>
                <div class="card" id="deliveryReports">
                    <h3>Delivery Reports</h3>
                    <p class="count">20 Reports</p>
                    <span class="info">
                        <span class="highlight">+50%</span> more than yesterday
                    </span>
                </div>
            </div>
            <div class="chart-container">
                <div class="chartCard w-100">
                    <h3 class="chart-heading">Delivery Orders Overview</h3>
                    <p-chart type="bar" [data]="data" [options]="options" />
                </div>
            </div>
            <div class="dashboard-container">
                <h2 class="dashboard-heading">Orders Dashboard</h2>
                <div class="button-group d-flex gap-2">
                    <button pButton type="button" label="Today" class="p-button-primary" (click)="filterTable('today')">
                    </button>
                    <button pButton type="button" label="Month" class="p-button-secondary"
                        (click)="filterTable('month')">
                    </button>
                </div>
                <p-table [value]="filteredData" class="orders-table">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Orders</th>
                            <th>In Progress</th>
                            <th>Pending</th>
                            <th>Delivered</th>
                            <th>Canceled</th>
                            <th>Sales</th>
                            <th>Trend</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order let-i="rowIndex">
                        <tr>
                            <td>{{ order.orders }}</td>
                            <td>{{ order.inProgress }}</td>
                            <td>{{ order.pending }}</td>
                            <td>{{ order.delivered }}</td>
                            <td>{{ order.canceled }}</td>
                            <td>{{ order.sales | currency: 'INR':'symbol' }}</td>
                            <td>
                                <p-chart type="line" [data]="getTrendData(order.orderTrendPoints)"
                                    [options]="chartOptions" class="trend-graph">
                                </p-chart>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div *ngIf="activeAction == 'Outlets'" class="outlets">
            <div class="outlets">
                <div class="card-container">
                    <div class="card" id="todayOutlets">
                        <h3>Today Outlets</h3>
                        <p class="count">5 Outlets</p>
                        <span class="info">
                            <span class="highlight">+10%</span> more than yesterday
                        </span>
                    </div>
                    <div class="card" id="monthlyOutlets">
                        <h3>Monthly Outlets</h3>
                        <p class="count">50 Outlets</p>
                        <span class="info">
                            <span class="highlight">+20%</span> more than last month
                        </span>
                    </div>
                    <div class="card" id="totalOutlets">
                        <h3>Total Outlets</h3>
                        <p class="count">150 Outlets</p>
                        <span class="info">
                            <span class="highlight">+30%</span> more than last year
                        </span>
                    </div>
                    <div class="card" id="activeReports">
                        <h3>Active Reports</h3>
                        <p class="count">12 Reports</p>
                        <span class="info">
                            <span class="highlight">+5%</span> more than last week
                        </span>
                    </div>
                </div>
                <div class="chart-container">
                    <div class="chartCard w-100">
                        <h3 class="chart-heading">Outlets Performance Overview</h3>
                        <p-chart type="bar" [data]="data" [options]="options" />
                    </div>
                </div>
                <div class="dashboard-container">
                    <h2 class="dashboard-heading">Outlets Dashboard</h2>
                    <div class="button-group d-flex gap-2">
                        <button pButton type="button" label="Today" class="p-button-primary"
                            (click)="filterTable('today')">
                        </button>
                        <button pButton type="button" label="Month" class="p-button-secondary"
                            (click)="filterTable('month')">
                        </button>
                    </div>
                    <p-table [value]="filteredData" class="outlets-table">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Outlets</th>
                                <th>Active</th>
                                <th>Offline</th>
                                <th>Disabled</th>
                                <th>Sales</th>
                                <th>Trend</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-outlet let-i="rowIndex">
                            <tr>
                                <td>{{ outlet.outlets }}</td>
                                <td>{{ outlet.active }}</td>
                                <td>{{ outlet.offline }}</td>
                                <td>{{ outlet.disabled }}</td>
                                <td>{{ outlet.outletsales | currency: 'INR':'symbol' }}</td>
                                <td>
                                    <p-chart type="line" [data]="getTrendData(outlet.outletTrendPoints)"
                                        [options]="chartOptions" class="trend-graph">
                                    </p-chart>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        <div *ngIf="activeAction == 'Finance'" class="finance">
            <div class="finance">
                <div class="card-container">
                    <div class="card" id="todayRevenue">
                        <h3>Today Revenue</h3>
                        <p class="count">1000 Rupees</p>
                        <span class="info">
                            <span class="highlight">+10%</span> more than yesterday
                        </span>
                    </div>
                    <div class="card" id="monthlyRevenue">
                        <h3>Monthly Revenue</h3>
                        <p class="count">10000 Rupees</p>
                        <span class="info">
                            <span class="highlight">+50%</span> more than last month
                        </span>
                    </div>
                    <div class="card" id="totalRevenue">
                        <h3>Total Revenue</h3>
                        <p class="count">100000 Revenue</p>
                        <span class="info">
                            <span class="highlight">+30%</span> more than last year
                        </span>
                    </div>
                    <div class="card" id="netProfit">
                        <h3>Net Profit</h3>
                        <p class="count">50000 Rupees</p>
                        <span class="info">
                            <span class="highlight">+5%</span> more than last week
                        </span>
                    </div>
                </div>
                <div class="chart-container">
                    <div class="chartCard w-100">
                        <h3 class="chart-heading">Finance Overview</h3>
                        <p-chart type="bar" [data]="data" [options]="options" />
                    </div>
                </div>
                <div class="dashboard-container">
                    <h2 class="dashboard-heading">Finance Dashboard</h2>
                    <div class="button-group d-flex gap-2">
                        <button pButton type="button" label="Today" class="p-button-primary"
                            (click)="filterTable('today')">
                        </button>
                        <button pButton type="button" label="Month" class="p-button-secondary"
                            (click)="filterTable('month')">
                        </button>
                    </div>
                    <p-table [value]="filteredData" class="finance-table">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Total Revenue</th>
                                <th>Net Profit</th>
                                <th>Trend</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-finance let-i="rowIndex">
                            <tr>
                                <td>{{ finance.totalRevenue }}</td>
                                <td>{{ finance.netProfit }}</td>
                                <td>
                                    <p-chart type="line" [data]="getTrendData(finance.revenueTrendPoints)"
                                        [options]="chartOptions" class="trend-graph">
                                    </p-chart>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        <div *ngIf="activeAction == 'Applications'" class="applications">
            <div class="applications">
                <div class="card-container">
                    <div class="card" id="todayApplications">
                        <h3>Today Applications</h3>
                        <p class="count">50 Applications</p>
                        <span class="info">
                            <span class="highlight">+10%</span> more than yesterday
                        </span>
                    </div>
                    <div class="card" id="monthlyApplications">
                        <h3>Monthly Applications</h3>
                        <p class="count">200 Applications</p>
                        <span class="info">
                            <span class="highlight">+20%</span> more than last month
                        </span>
                    </div>
                    <div class="card" id="totalApplications">
                        <h3>Total Applications</h3>
                        <p class="count">5000 Applications</p>
                        <span class="info">
                            <span class="highlight">+15%</span> more than last year
                        </span>
                    </div>
                    <div class="card" id="approvedApplications">
                        <h3>Approved Applications</h3>
                        <p class="count">3500 Applications</p>
                        <span class="info">
                            <span class="highlight">+5%</span> more than last week
                        </span>
                    </div>
                </div>
                <div class="chart-container">
                    <div class="chartCard w-100">
                        <h3 class="chart-heading">Applications Overview</h3>
                        <p-chart type="bar" [data]="data" [options]="options" />
                    </div>
                </div>
                <div class="dashboard-container">
                    <h2 class="dashboard-heading">Applications Dashboard</h2>
                    <div class="button-group d-flex gap-2">
                        <button pButton type="button" label="Today" class="p-button-primary"
                            (click)="filterTable('today')">
                        </button>
                        <button pButton type="button" label="Month" class="p-button-secondary"
                            (click)="filterTable('month')">
                        </button>
                    </div>
                    <p-table [value]="filteredData" class="applications-table">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Total Applications</th>
                                <th>Active</th>
                                <th>Pending</th>
                                <th>Rejected</th>
                                <th>Trend</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-application let-i="rowIndex">
                            <tr>
                                <td>{{ application.totalApplications }}</td>
                                <td>{{ application.active }}</td>
                                <td>{{ application.pending }}</td>
                                <td>{{ application.rejected }}</td>
                                <td>
                                    <p-chart type="line" [data]="getTrendData(application.applicationTrendPoints)"
                                        [options]="chartOptions" class="trend-graph">
                                    </p-chart>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>

    </div>
</div>