// Avoid circular dependency on EventEmitter by implementing a subset of the interface.
export class ErrorHandler {
  constructor() {
    this.listeners = [];
    this.unexpectedErrorHandler = function (e) {
      setTimeout(() => {
        if (e.stack) {
          throw new Error(e.message + '\n\n' + e.stack);
        }
        throw e;
      }, 0);
    };
  }
  emit(e) {
    this.listeners.forEach(listener => {
      listener(e);
    });
  }
  onUnexpectedError(e) {
    this.unexpectedErrorHandler(e);
    this.emit(e);
  }
  // For external errors, we don't want the listeners to be called
  onUnexpectedExternalError(e) {
    this.unexpectedErrorHandler(e);
  }
}
export const errorHandler = new ErrorHandler();
export function onUnexpectedError(e) {
  // ignore errors from cancelled promises
  if (!isPromiseCanceledError(e)) {
    errorHandler.onUnexpectedError(e);
  }
  return undefined;
}
export function onUnexpectedExternalError(e) {
  // ignore errors from cancelled promises
  if (!isPromiseCanceledError(e)) {
    errorHandler.onUnexpectedExternalError(e);
  }
  return undefined;
}
export function transformErrorForSerialization(error) {
  if (error instanceof Error) {
    let {
      name,
      message
    } = error;
    const stack = error.stacktrace || error.stack;
    return {
      $isError: true,
      name,
      message,
      stack
    };
  }
  // return as is
  return error;
}
const canceledName = 'Canceled';
/**
 * Checks if the given error is a promise in canceled state
 */
export function isPromiseCanceledError(error) {
  return error instanceof Error && error.name === canceledName && error.message === canceledName;
}
/**
 * Returns an error that signals cancellation.
 */
export function canceled() {
  const error = new Error(canceledName);
  error.name = error.message;
  return error;
}
export function illegalArgument(name) {
  if (name) {
    return new Error(`Illegal argument: ${name}`);
  } else {
    return new Error('Illegal argument');
  }
}
export function illegalState(name) {
  if (name) {
    return new Error(`Illegal state: ${name}`);
  } else {
    return new Error('Illegal state');
  }
}