import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavItemsService } from '../../services/nav-items.service';
import { AccessManagerService } from '../../services/access-manager.service';
import firebase from 'firebase/compat/app';
import { AuthService } from '../../services/auth.service';
import { GlobalDataService } from '../../services/global-data.service';
import { ToastService } from '../../shared/toast.service';
import { Router } from '@angular/router';
import * as lottie from 'lottie-web';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-access-manager',
  templateUrl: './access-manager.component.html',
  styleUrl: './access-manager.component.scss'
})
export class AccessManagerComponent {

  showSpinner: boolean = false
  showAddAccessRoleModal: boolean = false
  addAccessRoleForm: FormGroup;
  navItems: any[] = [];
  accessRoles: any[] = [];
  user: any;
  userData: any;
  isTableLoading: boolean = false;
  updateAMG: boolean = false;
  showDeleteConfirmationModal: boolean = false;
  selectedAMG: any;
  isDataLoading: boolean = false;
  @ViewChildren('animationContainer') animationContainers!: QueryList<ElementRef>;
  animationInstance: any;
  animationInstances: any[] = [];
  showAnimationContainer = false;

  // Map of animation container IDs to their respective animation file paths
  animationFilePaths: { [key: string]: string } = {
    trash: 'assets/animIcons/trashIcon.json',
    edit: 'assets/animIcons/penIcon.json',
    // pause: 'assets/animIcons/pauseIcon.json',
    // add: 'assets/animIcons/plusIcon.json'
  };

  constructor(private fb: FormBuilder, private navItemsService: NavItemsService, private accessManagerService: AccessManagerService,
    private globalDataService: GlobalDataService, private authService: AuthService, private toastService: ToastService, private router: Router, private http: HttpClient

  ) {
    this.addAccessRoleForm = this.fb.group({
      accessRoleName: ['', Validators.required],
      accessGroupList: ['', Validators.required],
    });
    if (this.globalDataService.checkAccess('Access Manager')) {
      this.getAccessGroupList()
      firebase.auth().onAuthStateChanged((user: any) => {
        if (user) {
          this.user = user;
          this.userData = this.globalDataService.getUserData();
          if (!this.userData) {
            this.authService.getCurrentStaff(this.user.uid).subscribe((res: any) => {
              this.userData = res.data();
              this.globalDataService.setUserData(this.userData);
            })
          }
        } else {
          console.warn("User not logged in");
          // User not logged in or has just logged out.
        }
      });
      this.navItemsService.getNavItems().subscribe((res: any) => {
        res.forEach((navItem: any) => {
          this.navItems.push(navItem)
        })
      })
    } else {
      this.router.navigate(['/home']);
      this.toastService.showError("You are not authorised to access this feature")
    }
  }

  ngAfterViewInit() {
    this.showAnimationContainer = true;
    this.isDataLoading = true;
    this.loadIcons();
  }

  loadIcons() {
    if (this.animationInstances.length > 0) {
      this.animationInstances.forEach((element: any) => {
        element.destroy();
      });
    }
    var timer = setInterval(() => {
      if (this.animationContainers.length > 0) {
        clearInterval(timer);
        this.animationContainers.forEach((animationContainer: ElementRef, index: number) => {
          const locationId = animationContainer.nativeElement.dataset.locationId;
          const animationType = animationContainer.nativeElement.dataset.animationType;
          const animationFilePath = this.animationFilePaths[animationType];

          this.http.get(animationFilePath).subscribe((animationData: any) => {
            const animationInstance = (lottie as any).loadAnimation({
              container: animationContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: false,
              animationData: animationData
            });

            animationInstance.setSpeed(0.5);

            const svgElement = animationContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              console.log("SVG element found:", svgElement);
              svgElement.style.fill = '#4b5563';
            } else {
              console.error("SVG element not found for container:", animationContainer.nativeElement);
            }

            this.animationInstances[index] = animationInstance;

            animationContainer.nativeElement.addEventListener('mouseenter', () => {
              this.animationInstances[index].setDirection(1);
              this.animationInstances[index].play();
            });

            animationContainer.nativeElement.addEventListener('mouseleave', () => {
              this.animationInstances[index].setDirection(-1);
              this.animationInstances[index].play();
            });
          });
        });
        this.isDataLoading = false;
      }
    },);
  }

  getAccessGroupList() {
    this.isTableLoading = true;
    this.accessRoles = [];
    this.accessManagerService.getAccessGroupList().subscribe((res: any) => {
      res.forEach((accessRole: any) => {
        this.accessRoles.push(accessRole)
      });
      this.isTableLoading = false;
    })
  }

  toggleAddAccessRoleModal(selectedAMG?: any) {
    this.showAddAccessRoleModal = !this.showAddAccessRoleModal;

    if (this.showAddAccessRoleModal) {
      if (selectedAMG) {
        this.selectedAMG = selectedAMG;
        this.updateAMG = true;

        // Patch values to the form
        this.addAccessRoleForm.patchValue({
          accessRoleName: selectedAMG.accessRoleName || '', // Provide a fallback if the property is undefined
          accessGroupList: selectedAMG.accessGroupList || '',
        });
      }
    } else {
      this.selectedAMG = null;
      this.addAccessRoleForm.reset(); // Optionally reset the form when the modal is closed
    }
  }


  addNewAccessRole() {
    this.showSpinner = true;
    var obj = {};
    if (!this.updateAMG) {
      obj = {
        accessRoleName: this.addAccessRoleForm.value.accessRoleName,
        accessGroupList: this.addAccessRoleForm.value.accessGroupList,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
        createdAt: new Date().getTime(),
        createdById: this.user.uid,
        createdBy: this.userData.firstName + ' ' + this.userData.lastName,
      };
      this.accessManagerService.addAccessGroup(obj).then((res: any) => {
        this.toastService.showSuccess("Access group addedd successfully")
        var obj = {
          notificationTitle: 'Added new access group',
          notificationDescription: `${this.addAccessRoleForm.value.accessGroupList} is added succesfully by ${this.userData.firstName + ' ' + this.userData.lastName}`,
          notificationTime: new Date().getTime(),
          severity: 'success'
        }
        this.globalDataService.superAdminIds.forEach((admin) => {
          this.globalDataService.sendNotification(admin, obj).then((res) => {
            // console.warn("notification sent");
          })
        })

        this.showSpinner = false;
        this.showAddAccessRoleModal = false;
        this.getAccessGroupList();
        this.loadIcons();
      }, err => {
        this.showSpinner = false;
        this.toastService.showError(err)
      })
    } else {
      obj = {
        accessRoleName: this.addAccessRoleForm.value.accessRoleName,
        accessGroupList: this.addAccessRoleForm.value.accessGroupList,
        lastUpdatedAt: new Date().getTime(),
        lastUpdatedById: this.user.uid,
        lastUpdatedBy: this.userData.firstName + ' ' + this.userData.lastName,
      };
      this.accessManagerService.updateAccessGroup(obj, this.selectedAMG.id).then((res: any) => {
        this.toastService.showSuccess("Access group addedd successfully")
        this.showSpinner = false;
        this.showAddAccessRoleModal = false;
        this.getAccessGroupList();
        this.loadIcons();
      }, err => {
        this.showSpinner = false;
        this.toastService.showError(err)
      })
    }
  }

  getFeatureNames(accessGroupList: any[]): string {
    return accessGroupList.map(item => item.featureName).join(', ');
  }

  toggleDeleteLocationConfirmation(accessGroup: any) {
    this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal;
    if (this.showDeleteConfirmationModal) {
      this.selectedAMG = accessGroup;
    } else {
      this.selectedAMG = null;
    }
  }

  deleteAMG(amgId: any) {
    this.showSpinner = true;
    this.accessManagerService.deleteAccessGroup(amgId).then((res: any) => {
      this.showDeleteConfirmationModal = false;
      this.showSpinner = false;
      this.getAccessGroupList();
      this.loadIcons();
      this.toastService.showSuccess('Delete successful', `${this.selectedAMG.accessRoleName} Access Group, has been succesfully delete.`)
    })
  }
}
