import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  customersCollection: AngularFirestoreCollection;
  
  constructor(private fireStore: AngularFirestore) {
    this.customersCollection = fireStore.collection('users');
   }

   getCustomers() {
    return this.customersCollection.get();
  }

  getOrderHistory(uid: any) {
    return this.customersCollection.doc(uid).collection('orders').get();
  }

  getWalletHistory(uid: any, date: any) {
    return this.customersCollection.doc(uid).collection('wallets').doc(date).collection('transactions').get();
  }
}
